import { all } from '@redux-saga/core/effects';
import { takeLatestWrapper } from './index';
import * as api from './../api/AssetAnalytics';

export default function* sagas() {
	yield all([takeLatestWrapper('FETCH_EVENT_STATUS_LIST', api.getEventStatusList),
		takeLatestWrapper('FETCH_SEVERITY_LIST', api.getSeverityList),
		takeLatestWrapper('FETCH_EVENT_CONFIGURATION', api.getEventConfigurationByThingId),
		takeLatestWrapper('CREATE_ASSET_ALERT', api.createAssetAnalyticsAlert)]);
}
