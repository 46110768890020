// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remaining-life-container {
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 300px; 
  }
  
  .label-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-size: 15px;
  }
  
  .alert-icon {
    color: red;
    margin-left: 4px;
    cursor: pointer;
    font-size: 22px;
  }
  
  .progress-bar {
    position: relative;
    height: 20px;
    background: linear-gradient(to right, #FF4136 0%, #FF851B 25%, #FFDC00 50%, #2ECC40 75%, #2ECC40 100% );
    border-radius: 10px;
  }
  
  .progress {
    height: 100%;
    background: black;
    border-radius: 10px 0 0 10px;
  }
  
  .marker {
    position: absolute;
    top: 10px; 
    width: 20px;
    height: 30px;
    background: black;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  
  .label-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }

  .days-remaining {
    font-size: 1.5em; 
    font-weight: bold;
    margin: 0 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/shared/components/RemainingLifeIndicator.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,uGAAuG;IACvG,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,iBAAiB;IACjB,4BAA4B;EAC9B;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,8CAA8C;EAChD;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;EACf","sourcesContent":[".remaining-life-container {\n    font-family: Arial, sans-serif;\n    width: 100%;\n    max-width: 300px; \n  }\n  \n  .label-top {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 8px;\n    font-size: 15px;\n  }\n  \n  .alert-icon {\n    color: red;\n    margin-left: 4px;\n    cursor: pointer;\n    font-size: 22px;\n  }\n  \n  .progress-bar {\n    position: relative;\n    height: 20px;\n    background: linear-gradient(to right, #FF4136 0%, #FF851B 25%, #FFDC00 50%, #2ECC40 75%, #2ECC40 100% );\n    border-radius: 10px;\n  }\n  \n  .progress {\n    height: 100%;\n    background: black;\n    border-radius: 10px 0 0 10px;\n  }\n  \n  .marker {\n    position: absolute;\n    top: 10px; \n    width: 20px;\n    height: 30px;\n    background: black;\n    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);\n  }\n  \n  .label-bottom {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 4px;\n  }\n\n  .days-remaining {\n    font-size: 1.5em; \n    font-weight: bold;\n    margin: 0 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
