import React, { useEffect, useState } from 'react';
import './Charts.css';
import { colors, isEmpty} from '../Util';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Chart as ChartJS,CategoryScale,	LinearScale,PointElement,LineElement,Title,	Tooltip,Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';

function TimeScaleChart({sliceCfgData, mode}) {
	TimeScaleChart.propTypes = {
		sliceCfgData: PropTypes.string.isRequired,
		mode:PropTypes.any.isRequired
	};
	let chartData=[];
	if (!isEmpty(sliceCfgData)) {
		let slicedata=JSON.parse(sliceCfgData);
		try{
			slicedata?.data?.map((item) => {
				chartData.push(item);
			});
		}catch (error) {
			//console.error('Exception in sliceCfgData & parsing==',error);
		}
	}
	let chartStartTime= Number.POSITIVE_INFINITY;
	let chartEndTime=Number.NEGATIVE_INFINITY;
	let defaultDateFormat='MM DD YYYY, HH:mm:ss';
	let xAxisFormat=defaultDateFormat;
	if(mode==='Shift' || mode==='Hour' || mode==='Day'){xAxisFormat='HH:mm';}else{xAxisFormat='MM-DD';}
	const [unPlanDownFlag, setUnPlanDownFlag] = useState(true);
	const [unPlanIdleFlag, setUnPlanIdleFlag] = useState(true);
	const [planDownFlag, setPlanDownFlag] = useState(true);
	const [planIdleFlag, setPlanIdleFlag] = useState(true);
	const [runFlag, setRunFlag] = useState(true);
	const [data, setData] = useState(buildChartData(chartData));

	useEffect(() => {
		ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
	}, []);
	
	useEffect(() => {
		setData(buildChartData(chartData));
	}, [unPlanDownFlag, unPlanIdleFlag, planDownFlag, planIdleFlag, runFlag]);
	

	const onClickLegand = (k) => {
		let ele=document.getElementById(k);
		if(k==='unPlannedDown'){
			if(ele.classList.contains('line-through-no-color')){
				ele.classList.remove('line-through-no-color');
				setUnPlanDownFlag(true);
				
			}else{
				ele.classList.add('line-through-no-color');	
				setUnPlanDownFlag(false);
			}
		}
		if(k==='unplannedIdle'){
			if(ele.classList.contains('line-through-no-color')){
				ele.classList.remove('line-through-no-color');
				setUnPlanIdleFlag(true);
			}else{
				ele.classList.add('line-through-no-color');	
				setUnPlanIdleFlag(false);
			}
		}
		if(k==='plannedDown'){
			if(ele.classList.contains('line-through-no-color')){
				ele.classList.remove('line-through-no-color');
				setPlanDownFlag(true);
			}else{
				ele.classList.add('line-through-no-color');	
				setPlanDownFlag(false);
			}
		}
		if(k==='plannedIdle'){
			if(ele.classList.contains('line-through-no-color')){
				ele.classList.remove('line-through-no-color');
				setPlanIdleFlag(true);
			}else{
				ele.classList.add('line-through-no-color');	
				setPlanIdleFlag(false);
			}
		}
		if(k==='running'){
			if(ele.classList.contains('line-through-no-color')){
				ele.classList.remove('line-through-no-color');
				setRunFlag(true);
			}else{
				ele.classList.add('line-through-no-color');	
				setRunFlag(false);
			}

		}
	};

	function buildChartData(data){
		let chData= {
			labels: [],
			datasets:getDataSet(data)
		};
		return chData;
	}

	function getDataSet(data){
		const json=[];
		if(!isEmpty(data)){
			let tmp=0;
			let tmp1=0;
			data?.map((item) => {
				if (item.status === 'Running' && runFlag) {
					tmp = item.startTime;
					if (tmp < chartStartTime) {
						chartStartTime = tmp;
					}
					tmp1 = item.endTime;
					if (tmp1 > chartEndTime) {
						chartEndTime = tmp1;
					}
					json.push(
						{   label: item,//'Running',
							backgroundColor:colors.green,
							borderColor: colors.green,
							fill: false,
							pointStyle: 'circle',
							pointRadius: alternatePointRadius,
							hoverRadius: alternatePointRadius,
							hoverStyle:null,
							data:[{
								x:moment(item.startTime).format(defaultDateFormat),
								y:1
							},{
								x:moment(item.endTime).format(defaultDateFormat),
								y:1
							}]});
				}
				if (item.status === 'Unplanned Down' && unPlanDownFlag) {
					json.push(
						{   label: item,//'Unplanned Down',
							backgroundColor:colors.red,
							borderColor: colors.red,
							fill: false,
							pointStyle: 'circle',
							pointRadius: 4,
							data:[{
								x:moment(item.startTime).format(defaultDateFormat),
								y:1
							},{
								x:moment(item.endTime).format(defaultDateFormat),
								y:1
							}]});
				}
				if (item.status === 'Planned Down' && planDownFlag) {
					json.push(
						{   label:item,// 'Planned Down',
							backgroundColor:colors.blue,
							borderColor: colors.blue,
							fill: false,
							pointStyle: 'circle',
							pointRadius: 4,
							data:[{
								x:moment(item.startTime).format(defaultDateFormat),
								y:1
							},{
								x:moment(item.endTime).format(defaultDateFormat),
								y:1
							}]});
				}
				if (item.status === 'Unplanned Idling' && unPlanIdleFlag) {
					json.push(
						{   label: item,//'Unplanned Idling',
							backgroundColor:colors.orange,
							borderColor: colors.orange,
							fill: false,
							pointStyle: 'circle',
							pointRadius: 4,
							data:[{
								x:moment(item.startTime).format(defaultDateFormat),
								y:1
							},{
								x:moment(item.endTime).format(defaultDateFormat),
								y:1
							}]});
				}
				if (item.status === 'Planned Idling' && planIdleFlag) {
					json.push(
						{   label: item,//'Planned Idling',
							backgroundColor:colors.gray,
							borderColor: colors.gray,
							fill: false,
							pointStyle: 'circle',
							pointRadius: 4,
							data:[{
								x:moment(item.startTime).format(defaultDateFormat),
								y:1
							},{
								x:moment(item.endTime).format(defaultDateFormat),
								y:1
							}]});
				}
			});
			return json;
		}}

		
	const getOrCreateTooltip = (chart) => {
		let tooltipEl = chart.canvas.parentNode.querySelector('div');

		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
			tooltipEl.style.borderRadius = '3px';
			tooltipEl.style.color = 'white';
			tooltipEl.style.opacity = 1;
			tooltipEl.style.pointerEvents = 'none';
			tooltipEl.style.position = 'absolute';
			tooltipEl.style.transform = 'translate(-50%, 0)';
			tooltipEl.style.transition = 'all .1s ease';

			const table = document.createElement('table');
			table.style.margin = '0px';

			tooltipEl.appendChild(table);
			chart.canvas.parentNode.appendChild(tooltipEl);
		}

		return tooltipEl;
	};

	const externalTooltipHandler = (context) => {
	// Tooltip Element
		const { chart, tooltip } = context;
		const tooltipEl = getOrCreateTooltip(chart);
		let lineStatus='';
		let startTime='';
		let endTime='';
		let reasonCode='';
		// Hide if no tooltip
		if (tooltip.opacity === 0) {
			tooltipEl.style.opacity = 0;
			return;
		}
		// Set Text
		if (tooltip.body) {
			const titleLines = tooltip.title || [];
			const tableHead = document.createElement('thead');

			titleLines.forEach((title) => {
				lineStatus=title.status;
				startTime=title.startTime;
				endTime=title.endTime;
				reasonCode=title.reason;
				const tr = document.createElement('tr');
				tr.style.borderWidth = 0;

				const th = document.createElement('th');
				th.style.borderWidth = 0;
				const text = document.createTextNode(chartData[0].machineName);

				th.appendChild(text);
				tr.appendChild(th);
				tableHead.appendChild(tr);
			});

			const tableBody = document.createElement('tbody');
			const tr = document.createElement('tr');
			const tr1 = document.createElement('tr');
			const tr2 = document.createElement('tr');
			const tr3 = document.createElement('tr');

			const td = document.createElement('td');
			const td0=document.createElement('td');

			const td1 = document.createElement('td');
			const td11 = document.createElement('td');

			const td2 = document.createElement('td');
			const td21 = document.createElement('td');

			const td3 = document.createElement('td');
			const td31 = document.createElement('td');

			const span = document.createElement('span');

			
			const text=document.createTextNode('Status');
			let text0='';
			if(lineStatus==='Running'){	text0 = document.createTextNode('Normal');}else{text0 = document.createTextNode(lineStatus);}

			const text1= document.createTextNode('Reason Code');
			const text11= document.createTextNode(reasonCode);

			const text2= document.createTextNode('Start Time');
			const text21= document.createTextNode(moment(startTime).format('DD MMM YYYY, H:mm'));

			const text3= document.createTextNode('End Time');
			const text31= document.createTextNode(moment(endTime).format('DD MMM YYYY, H:mm'));

			td.appendChild(span);
			td.appendChild(text);
			td0.appendChild(text0);
			tr.appendChild(td);
			tr.appendChild(td0);
			tableBody.appendChild(tr);

			td1.appendChild(span);
			td1.appendChild(text1);
			td11.appendChild(text11);
			tr1.appendChild(td1);
			tr1.appendChild(td11);
			tableBody.appendChild(tr1);

			td2.appendChild(span);
			td2.appendChild(text2);
			td21.appendChild(text21);
			tr2.appendChild(td2);
			tr2.appendChild(td21);
			tableBody.appendChild(tr2);

			td3.appendChild(span);
			td3.appendChild(text3);
			td31.appendChild(text31);
			tr3.appendChild(td3);
			tr3.appendChild(td31);
			tableBody.appendChild(tr3);

			const tableRoot = tooltipEl.querySelector('table');

			// Remove old children
			while (tableRoot.firstChild) {
				tableRoot.firstChild.remove();
			}

			// Add new children
			tableRoot.appendChild(tableHead);
			tableRoot.appendChild(tableBody);
		}

		const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

		// Display, position, and set styles for font
		tooltipEl.style.opacity = 1;
		tooltipEl.style.left = positionX + tooltip.caretX + 'px';
		tooltipEl.style.top = positionY + tooltip.caretY + 'px';
		tooltipEl.style.font = tooltip.options.bodyFont.string;
		tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
	};

	Tooltip.positioners.myCustomPositioner = function() {
		return {
			x: 650,
			y: -130
		};
	};
	
	const options = {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: 'x',
		scales: {
			x: {
				stacked: true,
				ticks: {
					//display: true
					callback: function(label) {
						return getLabelForValue(label);
					}
				},
				grid: {
					offset:false,
					display: true
				}
			},
			y: {
				stacked: false,
				ticks: {
					display: false
				},
				grid: {
					display: false,
					drawTicks: false
				}
			}
		},
		interaction: {
		//intersect: true,
			mode: 'dataset'
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				display: true
			},
			tooltip: {
				enabled: false,
				position: 'myCustomPositioner',
				external: externalTooltipHandler
			}
		}
	};

	const getLabelForValue= (context) => {
		let index=Number(context);
		if(!isEmpty(chartData[index])){
			context=moment(chartData[index].startTime).format(xAxisFormat);
		}else if(isEmpty(chartData[index])){
			context=moment(chartData[index-1].endTime).format(xAxisFormat);
		}
		return context;
	};

	function alternatePointRadius(ctx) {
		let data=ctx.raw;
		if(!isEmpty(data.x) && unPlanDownFlag && unPlanIdleFlag && planDownFlag && planIdleFlag ){
			if(data.x===moment(chartStartTime).format(defaultDateFormat) || data.x===moment(chartEndTime).format(defaultDateFormat)){
				return '4';
			}else{
				return null;
			}
		}else{
			return '4';
		}
	}

	return (
		<>
			<div id='legend-container'>
				<ul style={{display: 'flex', flexDirection: 'row', margin: '0px 280px', padding: '0px'}}>
					<li className='li-legend'  id='unPlannedDown' onClick={() => onClickLegand('unPlannedDown')}>
						<span className='legend-custom' style={{backgroundColor:colors.red}}></span>
						<p style={{color: 'rgb(102, 102, 102)'}}>Unplanned Down</p>
					</li>
					<li className='li-legend'  id='unplannedIdle' onClick={() => onClickLegand('unplannedIdle')}>
						<span className='legend-custom' style={{backgroundColor:colors.orange}}></span>
						<p style={{color: 'rgb(102, 102, 102)'	}}>Unplanned Idling</p>
					</li>
					<li className='li-legend'  id='plannedDown' onClick={() => onClickLegand('plannedDown')}>
						<span className='legend-custom' style={{backgroundColor:colors.blue}}></span>
						<p style={{color: 'rgb(102, 102, 102)'}}>Planned Down</p>
					</li>
					<li className='li-legend'  id='plannedIdle' onClick={() => onClickLegand('plannedIdle')}>
						<span className='legend-custom' style={{backgroundColor:colors.gray}}></span>
						<p style={{color: 'gray'}}>Planned Idling</p>
					</li>
					<li className='li-legend' id='running' onClick={() => onClickLegand('running')}>
						<span className='legend-custom' style={{backgroundColor:colors.green}}></span>
						<p style={{color: 'rgb(102, 102, 102)'}}>Running</p>
					</li>
					
				</ul>
			</div>
			<div style={{height:'110px'}}>
				{!isEmpty(data) &&
				<Line id='dumbbell' data={data} options={options} />
				}
			</div>
		</>
	);
}




export default TimeScaleChart;