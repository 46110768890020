import React from 'react';
import PropTypes from 'prop-types';
import { HvHeaderBrand } from '@hitachivantara/uikit-react-core';
import Logo from './shared/components/Logo';
import './App.css';
export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error,
			errorInfo
		});
		// You can also log error messages to an error reporting service here
	}

	render() {
		const { errorInfo } = this.state;
		const { children } = this.props;
		if (errorInfo) {
			return (
				<>
					<HvHeaderBrand logo={<Logo />} />
					<div id="error-page">
						<div className="content">
							<h2 className="header" data-text="404">
							404
							</h2>
							<h4 data-text="Opps! Page not found">Page not found</h4>
							<p>
							Sorry, the page you are looking for does not exist. If you think something is broken, report a problem.
							</p>
						</div>
					</div>
				</>
			);
		}
		// Normally, just render children
		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired
};
