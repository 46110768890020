import { all } from '@redux-saga/core/effects';
import { takeLatestWrapper } from './index';
import * as api from './../api/machine';

export default function* sagas() {
	yield all([takeLatestWrapper('FETCH_DEVICE_DETAILS', api.getDeviceDetails)]);
	yield all([takeLatestWrapper('FETCH_MACHINE_DOWNTIME_REASON', api.getDowntimeReasonDetailsApi)]);
	yield all([takeLatestWrapper('FETCH_MACHINE_OEE_CALCULATION', api.getOeeCalculationSummaryApi)]);
	yield all([takeLatestWrapper('FETCH_IMAGE_BYID', api.getImageById)]);
	yield all([takeLatestWrapper('FETCH_SLICE_CONFIGURATION_PARAMS', api.getSliceConfigurationParameters)]);
	yield all([takeLatestWrapper('FETCH_MACHINE_AVAILABILITY_INSIGHTS', api.getMachineAvailabilityInsights)]);
	yield all([takeLatestWrapper('FETCH_DOWNTIME_STATUS', api.getDowntimeStatusApi)]);
	yield all([takeLatestWrapper('FETCH_REASONCODES', api.getReasonCodesApi)]);
	yield all([takeLatestWrapper('CREATE_DOWNTIME', api.createDowntime)]);
	yield all([takeLatestWrapper('FETCH_DOWNTIME_LIST', api.getDownTimeList)]);
	yield all([takeLatestWrapper('DELETE_DOWNTIME', api.deleteDownTime)]);
	yield all([takeLatestWrapper('FETCH_SENSOR_VALUES_DROPDOWN', api.getSenValDropdownData)]);
	yield all([takeLatestWrapper('FETCH_ASSET_TIME_SERIES', api.getAssetTimeseries)]);
	yield all([takeLatestWrapper('FETCH_SEN_WINDOW_TIMESTAMP', api.getSenWindowTimestamps)]);
}