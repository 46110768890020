import React, { useEffect, useState } from 'react';
import { HvDropdown, HvDatePicker, HvTabs, HvTab } from '@hitachivantara/uikit-react-core';
import { HvVizProvider } from '@hitachivantara/uikit-react-viz';
import { getSenValDropdownData, getSenWindowTimestamps, getAssetTimeseries } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LineChartComponent from '../../shared/components/Charts/LineChartComponent';
import MultiLineChartComponent from '../../shared/components/Charts/MultiLineChartComponent';
import './Sensor.css';
import { hHVDATE } from '../../shared/components/Util';

function Sensor({thingId}) {
	Sensor.propTypes = {
		thingId: PropTypes.string.isRequired
	};

	const dispatch = useDispatch();
	const loading = useSelector((state) => state.machine.loading);
	const [hvDate, setHvDate] = useState(new Date());
	const [activeTab, setActiveTab] = useState(2);
	const [showLeftForwardMessage, setShowLeftForwardMessage] = useState(false);
	const [leftForwardMessage, setLeftForwardMessage] = useState('');
	const [showRightForwardMessage, setShowRightForwardMessage] = useState(false);
	const [rightForwardMessage, setRightForwardMessage] = useState('');
	const sensorValuesDropdown = useSelector((state) => state.machine.sensorValuesDropdown);
	const singleValueTabLabels = ['5m', '1h', 'Shift', '1d', '1w'];
	const tabModeMapping = {
		0: 'min',
		1: 'hour',
		2: 'shift',
		3: 'day',
		4: 'week'
	};
	const senWindowTimestamps = useSelector((state) => state.machine.senWindowTimestamps);
	const assetTimeseries = useSelector((state) => state.machine.assetTimeseries);
	const [xaxislabel, setXaxislabel] = useState([]);
	const [leftRightArrowClicked, setLeftRightArrowClicked] = useState(false);

	const getDefaultSelectedSensor = () => {
		const maxSensorId = Math.max(...sensorValuesDropdown.map((item) => item.sensor_id));
		const defaultSelectedItem = sensorValuesDropdown.find((item) => item.sensor_id === maxSensorId);
		return defaultSelectedItem ? [defaultSelectedItem.sensor_id] : [];
	};

	const [selectedSensorIds, setSelectedSensorIds] = useState(() => {
		const newDefaultSelectedSensorIds = getDefaultSelectedSensor(thingId);
		return newDefaultSelectedSensorIds;
	});

	useEffect(() => {
		const newDefaultSelectedSensorIds = getDefaultSelectedSensor(thingId);
		setSelectedSensorIds(newDefaultSelectedSensorIds);
		setActiveTab(2);
		dispatchToGetSenWindowTimestamps({ mode: tabModeMapping[2], hour_mode_duration: 1, thing_id: thingId, filter_datetime: new Date(hvDate).getTime(), filter_start: 0 , filter_end: 0 });
	}, [thingId, sensorValuesDropdown]);
 
	const noValueSelected = selectedSensorIds.length === 0;
	const isSingleValueSelected = selectedSensorIds.length === 1;
	const tabLabelsToUse = isSingleValueSelected ? singleValueTabLabels : singleValueTabLabels;

	useEffect(() => {
		dispatchToGetSenValDropdownData();
	}, [thingId]);

	const dispatchToGetSenValDropdownData = () => {
		dispatch(
			getSenValDropdownData({
				thingid: thingId
			})
		);
	};

	const dispatchToGetSenWindowTimestamps = (params) => {
		dispatch(
			getSenWindowTimestamps(params)
		);
	};

	useEffect(() => {
		if (!leftRightArrowClicked && (activeTab === 2 || activeTab === 3 || activeTab === 4)) {
			dispatchToGetSenWindowTimestamps({ mode: tabModeMapping[activeTab], hour_mode_duration: 1, thing_id: thingId, filter_datetime: new Date(hvDate).getTime(), filter_start: 0, filter_end: 0 });
		} else if (!leftRightArrowClicked && (activeTab === 0 || activeTab === 1)) {
			if (activeTab === 0) {
				generateTimestamps((hHVDATE(hvDate) -  5 * 60 * 1000), (hHVDATE(hvDate)), 1 * 60 * 1000);
				dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(hvDate) -  5 * 60 * 1000), endDate: (hHVDATE(hvDate)), timespanMode: tabModeMapping[activeTab]});
			} else if (activeTab === 1) {
				generateTimestamps((hHVDATE(hvDate) -  1 * 3600 * 1000), (hHVDATE(hvDate)), 5 * 60 * 1000);
				dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(hvDate) -  1 * 3600 * 1000), endDate: (hHVDATE(hvDate)), timespanMode: tabModeMapping[activeTab]});
			}
		} else {
			setLeftRightArrowClicked(false);
		}
	}, [hvDate, activeTab, thingId, selectedSensorIds]);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	useEffect(() => {
		if (senWindowTimestamps.length > 0 && selectedSensorIds.length > 0){
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: senWindowTimestamps[0].start_datetime_ep, endDate: senWindowTimestamps[0].shift_end_datetime_ep, timespanMode: tabModeMapping[activeTab]});
			if (activeTab === 2) {
				generateTimestamps(senWindowTimestamps[0].start_datetime_ep, senWindowTimestamps[0].shift_end_datetime_ep, 1 * 3600 * 1000);
			} else if (activeTab === 3) {
				generateTimestamps(senWindowTimestamps[0].start_datetime_ep, senWindowTimestamps[0].shift_end_datetime_ep, 2 * 3600 * 1000);
			} else if (activeTab === 4) {
				generateTimestamps(senWindowTimestamps[0].start_datetime_ep, senWindowTimestamps[0].shift_end_datetime_ep, 24 * 3600 * 1000);
			}
		}
	},[senWindowTimestamps]);

	const dispatchToGetAssetTimeSeries = (params) => {
		dispatch(
			getAssetTimeseries(params)
		);
	};

	function generateTimestamps(startTimestamp, endTimestamp, interval) {
		const timestamps = [];
		let currentTimestamp = startTimestamp;

		while (currentTimestamp <= endTimestamp) {
			timestamps.push(currentTimestamp);
			currentTimestamp += interval;
		}
		setXaxislabel(timestamps);
	}

	const modifyDateByHours = (timestampstr1, hours) => {
		const originalDate = new Date(timestampstr1);
		const modifiedDate = new Date(originalDate);
		modifiedDate.setHours(originalDate.getHours() + hours);
		return modifiedDate;
	};

	const modifyDateByMinutes = (timestampstr1, mins) => {
		const originalDate = new Date(timestampstr1);
		const modifiedDate = new Date(originalDate);
		modifiedDate.setMinutes(originalDate.getMinutes() + mins);
		return modifiedDate;
	};
	
	const handleHoverFirst = () => {
		if (activeTab === 0) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 5 minutes');
		} else if (activeTab === 1) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 1 hour');
		} else if (activeTab === 2) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 1 shift');
		} else if (activeTab === 3) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 1 day');
		} else if (activeTab === 4) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 1 week');
		} else {
			setShowLeftForwardMessage(false);
		}
	};

	const handleHoverPrev = () => {
		if (activeTab === 0) {
			
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 1 minute');
		} else if (activeTab === 1) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 5 minutes');
		} else if (activeTab === 2) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 1 hour');
		} else if (activeTab === 3) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 1 shift');
		} else if (activeTab === 4) {
			setShowLeftForwardMessage(true);
			setLeftForwardMessage('Back 1 day');
		} else {
			setShowLeftForwardMessage(false);
		}
	};

	const handleHoverForward = () => {
		if (activeTab === 0) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 1 minute');
		} else if (activeTab === 1) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 5 minutes');
		} else if (activeTab === 2) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 1 hour');
		} else if (activeTab === 3) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 1 shift');
		} else if (activeTab === 4) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 1 day');
		} else {
			setShowRightForwardMessage(false);
		}
	};

	const handleHoverLast = () => {
		if (activeTab === 0) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 5 minutes');
		} else if (activeTab === 1) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 1 hour');
		} else if (activeTab === 2) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 1 shift');
		} else if (activeTab === 3) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 1 day');
		} else if (activeTab === 4) {
			setShowRightForwardMessage(true);
			setRightForwardMessage('Forward 1 week');
		} else {
			setShowRightForwardMessage(false);
		}
	};

	const handleLeaveForward = () => {
		setShowLeftForwardMessage(false);
		setShowRightForwardMessage(false);
	};

	const handleFirstTab = () => {
		setLeftRightArrowClicked(true);
		if (activeTab === 0) {
			const newhvDate = modifyDateByMinutes(hvDate, -5);
			setHvDate(newhvDate);
			generateTimestamps((hHVDATE(newhvDate) -  5 * 60 * 1000), (hHVDATE(newhvDate)), 1 * 60 * 1000);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(newhvDate) -  5 * 60 * 1000), endDate: (hHVDATE(newhvDate)), timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 1) {
			const newhvDate = modifyDateByHours(hvDate, -1);
			setHvDate(newhvDate);
			generateTimestamps((hHVDATE(newhvDate) -  1 * 3600 * 1000), (hHVDATE(newhvDate)), 5 * 60 * 1000);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(newhvDate) -  1 * 3600 * 1000), endDate: (hHVDATE(newhvDate)), timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 2) {
			const newhvDate = modifyDateByHours(hvDate, -8);
			setHvDate(newhvDate);
			dispatchToGetSenWindowTimestamps({ mode: tabModeMapping[activeTab], hour_mode_duration: 1, thing_id: thingId, filter_datetime: new Date(newhvDate).getTime(), filter_start: 0 , filter_end: 0 });
		} else if (activeTab === 3) {
			const newhvDate = modifyDateByHours(hvDate, -24);
			setHvDate(newhvDate);
			dispatchToGetSenWindowTimestamps({ mode: tabModeMapping[activeTab], hour_mode_duration: 1, thing_id: thingId, filter_datetime: new Date(newhvDate).getTime(), filter_start: 0 , filter_end: 0 });
		} else if (activeTab === 4) {
			const newhvDate = modifyDateByHours(hvDate, -168);
			setHvDate(newhvDate);
			dispatchToGetSenWindowTimestamps({ mode: tabModeMapping[activeTab], hour_mode_duration: 1, thing_id: thingId, filter_datetime: new Date(newhvDate).getTime(), filter_start: 0 , filter_end: 0 });
		} else {
			setShowLeftForwardMessage(false);
			setShowRightForwardMessage(false);
		}
	};

	const handlePrevTab = () => {
		setLeftRightArrowClicked(true);
		if (activeTab === 0) {
			const newhvDate = modifyDateByMinutes(hvDate, -1);
			setHvDate(newhvDate);
			generateTimestamps((hHVDATE(newhvDate) -  5 * 60 * 1000), (hHVDATE(newhvDate)), 1 * 60 * 1000);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(newhvDate) -  5 * 60 * 1000), endDate: (hHVDATE(newhvDate)), timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 1) {
			const newhvDate = modifyDateByMinutes(hvDate, -5);
			setHvDate(newhvDate);
			generateTimestamps((hHVDATE(newhvDate) -  1 * 3600 * 1000), (hHVDATE(newhvDate)), 5 * 60 * 1000);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(newhvDate) -  1 * 3600 * 1000), endDate: (hHVDATE(newhvDate)), timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 2) {
			const modifiedXaxisLabel = xaxislabel.map((timestamp) => timestamp - 3600 * 1000);
			setXaxislabel(modifiedXaxisLabel);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: modifiedXaxisLabel[0], endDate: modifiedXaxisLabel[modifiedXaxisLabel.length - 1], timespanMode: tabModeMapping[activeTab]});
			const newhvDate = modifyDateByHours(hvDate, -1);
			setHvDate(newhvDate);
		} else if (activeTab === 3) {
			const newhvDate = modifyDateByHours(hvDate, -8);
			setHvDate(newhvDate);
			const modifiedXaxisLabel = xaxislabel.map((timestamp) => timestamp - 8 * 3600 * 1000);
			setXaxislabel(modifiedXaxisLabel);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: modifiedXaxisLabel[0], endDate: modifiedXaxisLabel[modifiedXaxisLabel.length - 1], timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 4) {
			const newhvDate = modifyDateByHours(hvDate, -24);
			setHvDate(newhvDate);
			const modifiedXaxisLabel = xaxislabel.map((timestamp) => timestamp - 24 * 3600 * 1000);
			setXaxislabel(modifiedXaxisLabel);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: modifiedXaxisLabel[0], endDate: modifiedXaxisLabel[modifiedXaxisLabel.length - 1], timespanMode: tabModeMapping[activeTab]});
		} else {
			setShowLeftForwardMessage(false);
			setShowRightForwardMessage(false);
		} 
	};

	const handleNextTab = () => {
		setLeftRightArrowClicked(true);
		if (activeTab === 0) {
			const newhvDate = modifyDateByMinutes(hvDate, +1);
			setHvDate(newhvDate);
			generateTimestamps((hHVDATE(newhvDate) -  5 * 60 * 1000), (hHVDATE(newhvDate)), 1 * 60 * 1000);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(newhvDate) -  5 * 60 * 1000), endDate: (hHVDATE(newhvDate)), timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 1) {
			const newhvDate = modifyDateByMinutes(hvDate, +5);
			setHvDate(newhvDate);
			generateTimestamps((hHVDATE(newhvDate) -  1 * 3600 * 1000), (hHVDATE(newhvDate)), 5 * 60 * 1000);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(newhvDate) -  1 * 3600 * 1000), endDate: (hHVDATE(newhvDate)), timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 2) {
			const newhvDate = modifyDateByHours(hvDate, +1);
			setHvDate(newhvDate);
			const modifiedXaxisLabel = xaxislabel.map((timestamp) => timestamp + 3600 * 1000);
			setXaxislabel(modifiedXaxisLabel);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: modifiedXaxisLabel[0], endDate: modifiedXaxisLabel[modifiedXaxisLabel.length - 1], timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 3) {
			const newhvDate = modifyDateByHours(hvDate, +8);
			setHvDate(newhvDate);
			const modifiedXaxisLabel = xaxislabel.map((timestamp) => timestamp + 8 * 3600 * 1000);
			setXaxislabel(modifiedXaxisLabel);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: modifiedXaxisLabel[0], endDate: modifiedXaxisLabel[modifiedXaxisLabel.length - 1], timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 4) {
			const newhvDate = modifyDateByHours(hvDate, +24);
			setHvDate(newhvDate);
			const modifiedXaxisLabel = xaxislabel.map((timestamp) => timestamp + 24 * 3600 * 1000);
			setXaxislabel(modifiedXaxisLabel);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: modifiedXaxisLabel[0], endDate: modifiedXaxisLabel[modifiedXaxisLabel.length - 1], timespanMode: tabModeMapping[activeTab]});
		} else {
			setShowLeftForwardMessage(false);
			setShowRightForwardMessage(false);
		}
	};

	const handleLastTab = () => {
		setLeftRightArrowClicked(true);
		if (activeTab === 0) {
			const newhvDate = modifyDateByMinutes(hvDate, +5);
			setHvDate(newhvDate);
			generateTimestamps((hHVDATE(newhvDate) -  5 * 60 * 1000), (hHVDATE(newhvDate)), 1 * 60 * 1000);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(newhvDate) -  5 * 60 * 1000), endDate: (hHVDATE(newhvDate)), timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 1) {
			const newhvDate = modifyDateByHours(hvDate, +1);
			setHvDate(newhvDate);
			generateTimestamps((hHVDATE(newhvDate) -  1 * 3600 * 1000), (hHVDATE(newhvDate)), 5 * 60 * 1000);
			dispatchToGetAssetTimeSeries({id: selectedSensorIds, startDate: (hHVDATE(newhvDate) -  1 * 3600 * 1000), endDate: (hHVDATE(newhvDate)), timespanMode: tabModeMapping[activeTab]});
		} else if (activeTab === 2) {
			const newhvDate = modifyDateByHours(hvDate, +8);
			setHvDate(newhvDate);
			dispatchToGetSenWindowTimestamps({ mode: tabModeMapping[activeTab], hour_mode_duration: 1, thing_id: thingId, filter_datetime: new Date(newhvDate).getTime(), filter_start: 0 , filter_end: 0 });
		} else if (activeTab === 3) {
			const newhvDate = modifyDateByHours(hvDate, +24);
			setHvDate(newhvDate);
			dispatchToGetSenWindowTimestamps({ mode: tabModeMapping[activeTab], hour_mode_duration: 1, thing_id: thingId, filter_datetime: new Date(newhvDate).getTime(), filter_start: 0 , filter_end: 0 });
		} else if (activeTab === 4) {
			const newhvDate = modifyDateByHours(hvDate, +168);
			setHvDate(newhvDate);
			dispatchToGetSenWindowTimestamps({ mode: tabModeMapping[activeTab], hour_mode_duration: 1, thing_id: thingId, filter_datetime: new Date(newhvDate).getTime(), filter_start: 0 , filter_end: 0 });
		} else {
			setShowLeftForwardMessage(false);
			setShowRightForwardMessage(false);
		}
	};

	const handleDropdownChange = (selectedItems) => {
		if (selectedItems.length <= 6) {
			const selectedIds = selectedItems.map((item) => item.value);
			setSelectedSensorIds(selectedIds);
		} 
	};

	return (
		<>
			<div className='containerStyle' >
				<h1 className='headerStyle' >Sensor Values</h1>
				<HvDatePicker
					value={hvDate}
					onChange={(newDate) => {
						const updatedDate = new Date(newDate);
						updatedDate.setHours(hvDate.getHours());
						updatedDate.setMinutes(hvDate.getMinutes());
						updatedDate.setSeconds(hvDate.getSeconds());
						setHvDate(updatedDate);
					}}
					className='datePickerStyle'
					calendarProps={{
						maximumDate: new Date()
					}}
				/>
			</div>
			<div className='tabsContainerStyle'>
				<HvDropdown
					aria-label="Right"
					disablePortal
					id="dropdown2"
					multiSelect
					showSearch
					placement="left"
					onChange={handleDropdownChange}
					values={sensorValuesDropdown
						.slice()
						.sort((a, b) => b.sensor_id - a.sensor_id)
						.map((item) => ({
							label: item.sensor_name,
							value: item.sensor_id,
							selected: selectedSensorIds.includes(item.sensor_id)
						}))
					}
					style={{
						height: '40px',
						width: '400px',
						padding: '8px',
						marginRight: '650px'
					}}
				/>
				<div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
					<button onClick={handleFirstTab} onMouseEnter={handleHoverFirst} onMouseLeave={handleLeaveForward}>
						{'<<'}
					</button>
					<button onClick={handlePrevTab} onMouseEnter={handleHoverPrev} onMouseLeave={handleLeaveForward}>
						{'<'}
					</button>
					<div className='leftforwardMessageStyle' style={{ display: showLeftForwardMessage ? 'block' : 'none', width: '120px'}}>
						{leftForwardMessage} 
					</div>

					<HvTabs value={activeTab} onChange={handleTabChange}>
						{tabLabelsToUse.map((label, index) => (
							<HvTab
								key={label}
								label={label}
								value={index}
								style={{
									backgroundColor: activeTab === index ? '#FBFCFC' : 'transparent',
									color: activeTab === index ? '#000' : 'black'
								}}
							/>
						))}
					</HvTabs>
					<button onClick={handleNextTab} onMouseEnter={handleHoverForward} onMouseLeave={handleLeaveForward}>
						{'>'}
					</button>
					<button onClick={handleLastTab} onMouseEnter={handleHoverLast} onMouseLeave={handleLeaveForward}>
						{'>>'}
					</button>
					<div className='rightforwardMessageStyle' style={{ display: showRightForwardMessage ? 'block' : 'none' }}>
						{rightForwardMessage}
					</div>
				</div>
			</div>
			<HvVizProvider>
				{noValueSelected ? (
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25%' }}>
						<p>No Data Found</p>
					</div>
				) : isSingleValueSelected ? (
					<LineChartComponent data={assetTimeseries} CustomXAxisTick={xaxislabel} loading={loading} currentTab={activeTab}/>
				) : (
					<MultiLineChartComponent data={assetTimeseries} CustomXAxisTick={xaxislabel} loading={loading} currentTab={activeTab}/>
				)}
			</HvVizProvider>
		</>
	);
}

export default Sensor;
