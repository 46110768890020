import React, { useState } from 'react';
import {
	HvLogin,
	HvInput,
	HvTypography,
	HvSnackbar,
	HvButton,
	HvHeaderBrand
} from '@hitachivantara/uikit-react-core';
import Logo from '../components/Logo';
import background from '../../assets/images/background.png';
import './Login.css';	
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { loginRequest } from '../Auth/authConfig';
import {  useMsal } from '@azure/msal-react';

async function loginUser(username, password) {
	let response;
	if(username ==='admin' && password==='P@ssword$#364'){
		response = {
			data: {
				id: 1,
				username: '206',
				name: 'Admin',
				defaultAppSelected: 1
			}
		};
	}
	else{
		const error ='Invali user';
		throw error;
	}
	
	return response.data;
}


function Login({ setToken }) {
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const { instance } = useMsal();
	// const [formData, setFormData] = useState({ username: '', password: '' });
	const handleSnackBarClose = React.useCallback(() => {
		setError(false);
	}, [setError]);

	const handleSubmit = async (username, password) => {
		setLoading(true);
		
		try {
			const token = await loginUser(username, password);
			setToken(token);
		} catch (e) {
			setLoading(false);
			setError('Invalid username or password!!!');
		}
		setLoading(false);
	};

	const formik = useFormik({
		initialValues: {
			username: '',
			password: ''
		},
		validationSchema: Yup.object({
			username: Yup.string().required('Required'),
			password: Yup.string()
				.min(3, 'Password is too short - should be 3 chars minimum.')
				.required('Please provide a valid password')
		}),
		onSubmit: (values) => handleSubmit(values.username, values.password)
	});

	const handleLoginRedirect = () => {
		
		instance.loginRedirect(loginRequest).catch((error));
	};	
	return (
		<div>
			<HvHeaderBrand logo={<Logo />} />
			<HvLogin background={background} className="custom-background">
				<div className="root">
					<HvSnackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={!!error}
						onClose={handleSnackBarClose}
						autoHideDuration={6000}
						variant="error"
						label="Invalid username or password!"
						showIcon
					/>
					<HvTypography variant="title2">Welcome</HvTypography>
					<form onSubmit={handleSubmit}>
						<HvInput
							id="username"
							label="Username"
							name="username"
							placeholder="Enter text"
							required
							disabled={loading}
							onChange={formik.handleChange}
							value={formik.values.username}
							className="login-text"
						/>
						<HvInput
							id="password"
							label="Password"
							name="password"
							placeholder="Enter text"
							required
							disabled={loading}
							onChange={formik.handleChange}
							value={formik.values.password}
							type="password"
							className="login-text"
						/>

						<HvButton
							type="submit"
							variant="primary"
							className="login-btn"
							disabled={loading}
							onClick={formik.handleSubmit}>
							Login
						</HvButton>
					</form>
					<hr></hr>
					<div className="login-text">
						<HvButton variant="secondaryGhost" onClick={handleLoginRedirect} className="red-btn">
						Login with Single Sign On
						</HvButton>
					</div>
				</div>
			</HvLogin>
		</div>
	);
}

Login.propTypes = {
	setToken: PropTypes.func.isRequired
};

export default Login;
