import './App.css';
import React, { Suspense, lazy, useState } from 'react';
import { HvContainer, HvGrid, HvProvider,ds3, ds5 } from '@hitachivantara/uikit-react-core';
import Login from './shared/Login/Login';
import useToken from './useToken';
import ErrorBoundary from './ErrorBoundary';
import { Routes, Route, Navigate } from 'react-router-dom';
import Loading from './shared/Loading/Loading';
import theme from './Theme';
import Machine from './ma/Machine';
import QualityAnalytics from './qa/QualityAnalytics';
import Breadcrumb from './shared/components/Breadcrumb/Breadcrumb';
import { MsalProvider, useMsal} from '@azure/msal-react';
import PropTypes from 'prop-types';
import { isEmpty } from './shared/components/Util';

const Header = lazy(() => import('./shared/components/Header/Header.component'));
const Dashboard = lazy(() => import('./ma/Dashboard'));
const VerticalNavigation = lazy(() => import('./shared/components/VerticalNavigation'));
const Assetanalytics = lazy(() => import('./ma/Assetanalytics/Assetanalytics'));
const Process = lazy(() => import('./ma/Process'));
const Insights = lazy(() => import('./ma/Insights'));
const Forecast = lazy(() => import('./ma/Forecast'));
const Factory = lazy(() => import('./ma/Factory'));
const Line = lazy(() => import('./ma/Line'));
const Alerts = lazy(() => import('./ma/Alerts'));
const Sensor = lazy(() => import('./ma/Sensor/Sensor.js'));
const TankMaster = lazy(() => import('./tank-master/TankMaster'));
const Prognostics = lazy(() => import('./ma/Prognostics/Prognostics.js'));

function AppData() {
	AppData.propTypes = {
		MSLInstance:PropTypes.any
	};

	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();
	
	const { token, setToken } = useToken();
	const [colorMode, setColorMode] = useState('dawn');
	const toggleColorMode = () => {
		setColorMode((prevMode) => (prevMode === 'dawn' ? 'wicked' : 'dawn'));
	};	

	if(!token && !isEmpty(activeAccount) && !isEmpty(activeAccount.username))
	{
		const response = {
			data: {
				id: 1,
				username: activeAccount.username,
				name:activeAccount.name,
				defaultAppSelected: 1,
				idTokens:activeAccount.idTokenClaims
			}
		};
		setToken(response.data);
	}
	
	if (!token) {
		return (
			<HvProvider style={{padding: 0}} themes={[ds3, ds5]} theme="ds3" colorMode={colorMode}>
				{/* dawn wicked */}
				<HvContainer>
					<Login setToken={setToken} />
				</HvContainer>
			</HvProvider>
		);
	}

	return (
		<ErrorBoundary>
			<HvProvider themes={[ds3, ds5]} theme={theme} colorMode={colorMode}>
				<HvContainer style={{padding: 0}}>
					<Suspense fallback={<Loading />}>
						<Header setToken={setToken} toggleColorMode={toggleColorMode} />
						<HvGrid item md={12}>
							<HvGrid container>
								<HvGrid item md={0.5}>
									<VerticalNavigation className="leftNavPos" />
								</HvGrid>
								<HvGrid item md={11.5} style={{paddingRight: '20px'}}>
									<div className='main-page'>
										<Breadcrumb />
										<Routes>
											<Route path="/" element={<Navigate to="/dashboard" />} />
											<Route path="/dashboard" element={<Dashboard />} />
											<Route path="/equipment/assetanalytics" element={<Assetanalytics />} />
											<Route path="/process" element={<Process />} />
											<Route path="/quality/insights" element={<Insights />} />
											<Route path="/quality/forecast" element={<Forecast />} />
											<Route path="/factory" element={<Factory />} />
											<Route path="/line" element={<Line />} />
											<Route path="/machine" element={<Machine />} />
											<Route path="/qualityanalytics" element={<QualityAnalytics />} />
											<Route path='/sensor' element={<Sensor />} />
											<Route path='/tankmaster' element={<TankMaster />} />
											<Route path="/qualityanalytics" element={<QualityAnalytics/>} />
											<Route path='/alert' element={<Alerts/>} />
											<Route path='/prognostics' element={<Prognostics/>} />
											<Route path="*" element={<Navigate to="/dashboard" />} />
										</Routes>
									</div>
								</HvGrid>
							</HvGrid>
						</HvGrid>
					</Suspense>
				</HvContainer>
			</HvProvider>
		</ErrorBoundary>
	);
}
const App = ({ instance }) => {
	App.propTypes = {
		instance:PropTypes.any
	};
	return (
		<MsalProvider instance={instance}>
			<AppData />
		</MsalProvider>
	);
};

export default App;
