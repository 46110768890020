const initialState = {
	loading: false,
	tankMasterSearchResult: [],
	finAttachAlertdata:[],
	finAlertdata:[],
	finAllMachineDataResult:[],
	finDropdownChartData:[],
	finMachineChartData:[],
	tankData:{}
};

const TankMasterReducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'UPDATE_TANK':
		return {
			...state,
			tankData: payload
		};
	case 'FETCH_TANK_SEARCH_RESULTS_REQUESTED':
		return {
			...state,
			loading: true,
			tankMasterSearchResult: []
		};
	case 'FETCH_TANK_SEARCH_RESULTS_SUCCEEDED':
		return {
			...state,
			loading: false,
			tankMasterSearchResult: payload
		};
	case 'FETCH_TANK_SEARCH_RESULTS_FAILED':
		return {
			...state,
			loading: false,
			tankMasterSearchResult: payload
		};
	case 'FETCH_ALL_MACHINE_DATA_REQUESTED':
		return {
			...state,
			loading: true,
			finAllMachineDataResult: []
		};
	case 'FETCH_ALL_MACHINE_DATA_SUCCEEDED':
		return {
			...state,
			loading: false,
			finAllMachineDataResult: payload
		};
	case 'FETCH_ALL_MACHINE_DATA_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_DROPDOWN_CHARTDATA_REQUESTED':
		return {
			...state,
			loading: true,
			finDropdownChartData: []
		};
	case 'FETCH_DROPDOWN_CHARTDATA_SUCCEEDED':
		return {
			...state,
			loading: false,
			finDropdownChartData: payload
		};
	case 'FETCH_DROPDOWN_CHARTDATA_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_MACHINE_CHART_DATA_REQUESTED':
		return {
			...state,
			loading: true,
			finMachineChartData: []
		};
	case 'FETCH_MACHINE_CHART_DATA_SUCCEEDED':
		return {
			...state,
			loading: false,
			finMachineChartData: payload
		};
	case 'FETCH_MACHINE_CHART_DATA_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_MACHINE_ALERT_DATA_REQUESTED':
		return {
			...state,
			loading: true,
			finAlertdata: []
		};
	case 'FETCH_MACHINE_ALERT_DATA_SUCCEEDED':
		return {
			...state,
			loading: false,
			finAlertdata: payload
		};
	case 'FETCH_MACHINE_ALERT_DATA_FAILED':
		return {
			...state,
			loading: false
		};			
	default:
		return {
			...state
		};
	}
};

export default TankMasterReducer;
