import React, { useState, useEffect } from 'react';
import {
	HvTable,
	HvTableBody,
	HvTableCell,
	HvTableContainer,
	HvTableHead,
	HvTableHeader,
	HvTableRow
} from '@hitachivantara/uikit-react-core';
import PropTypes from 'prop-types';
import { upArrow} from '../Util';
import './SimpleTable.css';
import Loading from '../../Loading/Loading';

function SimpleTable({ columns, data ,loading }) {
	const [tableData, setTableData] = useState(data);

	useEffect(() => {
		setTableData(data);
	}, [data]);

	let sorting = true;

	const getArrowColors = (status) =>{
		switch(status.toLowerCase()){
		case 'healthy':
			return upArrow('rgb(99, 166, 33)');
		case 'warning':
			return upArrow('rgb(231, 120, 77)');
		case 'critical':
			return upArrow('rgb(255, 82, 82)');
		case '':
			return upArrow('rgb(255, 82, 82)');
		default: 
			return upArrow('rgb(99, 166, 33)');
		}
	};
	const isNumeric = (val) => !isNaN(val) && !isNaN(parseFloat(val));

	const getCellStyle = (value, rowData) => {
		const numValue = parseFloat(value);
		const critical = parseFloat(rowData.critical);
		const warning = parseFloat(rowData.warning);
		const healthy = parseFloat(rowData.healthy);
		let style = {};
		let arrow = '';
		const displayValue = isNumeric(value) ? `${value}%` : value;

		if (numValue === critical && critical >= warning && critical >= healthy) {
			style = { fontWeight: 'bold', color: 'rgb(255, 82, 82)', fontSize: '15px' , display: 'inline-flex', alignItems: 'center' };
			arrow = getArrowColors('critical');
		} else if (numValue === warning && warning > critical && warning >= healthy) {
			style = { fontWeight: 'bold', color: 'rgb(231, 120, 77)',fontSize: '15px' , display: 'inline-flex', alignItems: 'center'};
			arrow = getArrowColors('warning');
		} else if (numValue === healthy && healthy > critical && healthy > warning) {
			style = { fontWeight: 'bold', color: 'rgb(99, 166, 33)',fontSize: '15px' , display: 'inline-flex', alignItems: 'center'};
			arrow = '';
		}

		return (
			<span style={style}>{displayValue}{arrow && <span style={{ marginLeft: '4px', verticalAlign: 'middle' }}>{arrow}</span>}
			</span>
		);
	};

	const getTableCell = (rowData, column) => {
		return (
			<HvTableCell>
				{getCellStyle(rowData[column.accessor], rowData)}
			</HvTableCell>
		);
	};

	const headerClick = (header) => {
		let sortedData = [...tableData].sort((a, b) => {
			let fa = header.valueType === 'number' ? Number(a[header.accessor]) : String(a[header.accessor]).toLowerCase(),
				fb = header.valueType === 'number' ? Number(b[header.accessor]) : String(b[header.accessor]).toLowerCase();

			if (fa < fb) {
				return sorting ? -1 : 1;
			}
			if (fa > fb) {
				return sorting ? 1 : -1;
			}
			return 0;
		});
		setTableData(sortedData);
		sorting = !sorting;
	};

	return (<>
		{loading && <Loading />}
		{!loading && columns?.length !== 0 && (
			<HvTableContainer>
				<HvTable>
					<HvTableHead>
						<HvTableRow>
							{columns.map((col) => (
								<HvTableHeader key={col.accessor} onClick={() => headerClick(col)} sortable >
									{col.Header}
								</HvTableHeader>
							))}
						</HvTableRow>
					</HvTableHead>
					<HvTableBody>
						{tableData.map((rowData, index) => (
							<HvTableRow key={`row-${index}`}>
								{columns.map((column) => getTableCell(rowData, column))}
							</HvTableRow>
						))}
					</HvTableBody>
				</HvTable>
			</HvTableContainer>)}
	</>);
}

SimpleTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	loading: PropTypes.bool
};

export default SimpleTable;
