import React, { useEffect, useState } from 'react';
import { HvGrid, HvTab, HvTabs, HvTypography } from '@hitachivantara/uikit-react-core';
import PropTypes from 'prop-types';
import { Machine } from '@hitachivantara/uikit-react-icons';
import { Trends } from '@hitachivantara/uikit-react-icons';
import MachineAvailability from './MachineAvailability';
import Sensor from './Sensor/Sensor';
import { useLocation } from 'react-router';

function MachineLog({ maData, uid, thingId }) {
	MachineLog.propTypes = {
		maData: PropTypes.object.isRequired,
		uid: PropTypes.string.isRequired,
		thingId: PropTypes.string.isRequired
	};
	const location = useLocation();
	const [value, setValue] = useState(0);

	useEffect(() => {
		setValue(0);
	}, [location]);

	const handleChange = (e, newValue) => setValue(newValue);

	return (
		<HvTypography className='titleBox' style={{height: 'auto'}}>
			<HvGrid container>
				<HvGrid item md={12}>
					<HvTypography variant="title3" >
						<div style={{ display: 'flex' }}>
							<HvTabs value={value} onChange={handleChange} >
								<HvTab icon={<Trends />} key='0' tabIndex={0} id="maSensors" iconPosition="start" label="Sensors" />
								<HvTab icon={<Machine />} key='1' tabIndex={1} id="maMachine" iconPosition="start" label="Machine" />
							</HvTabs>
						</div>
					</HvTypography>
					<div style={{ width: '100%', height: '90%', padding: '20px', borderRadius: '0px 0px 6px 6px'}}>
						{value === 1 && <MachineAvailability uid={uid} maData={maData} /> }
						{value === 0 && <Sensor thingId={thingId} />}
					</div>
				</HvGrid>
			</HvGrid>
		</HvTypography>
	);
}
export default MachineLog;
