import {
	HvDialog,
	HvDialogContent,
	HvDialogTitle,
	HvTable,
	HvTableBody,
	HvTableCell,
	HvTableContainer,
	HvTableRow
} from '@hitachivantara/uikit-react-core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from './Util';

function Popup({thingName,data,parentCallback}) {
	Popup.propTypes = {
		thingName: PropTypes.any.isRequired,
		data: PropTypes.array.isRequired,
		parentCallback:PropTypes.any
	};
	
	const [open, setOpen] = useState(false);
	const [detailedData, setDetailedData] = useState([]);
	const onOpen = () => {
		setOpen(true);
	};

	useEffect(() => {
		onOpen();
		if(!isEmpty(data)){
			data.map((item) => {
				if(item.length>1){
					if (item[0].category==='UnplannedDown') {
						setDetailedData(item);
					}
				}else if (item[0].category==='UnplannedDown') {
					setDetailedData(item);
				}
			});
		}
	}, []);

	const onClose = () => {
		setOpen(false);
		parentCallback(false);
	};
	const showDowntimeDetails =(item,data) =>{
		setDetailedData(data);
		let unplanned =document.getElementById('unplanned');
		let unplannedIdling =document.getElementById('unplannedIdling');
		let plannedDown =document.getElementById('plannedDown');
		let plannedIdling =document.getElementById('plannedIdling');
		let running =document.getElementById('running');
		if(item ==='unplanned'){
			unplanned.className='red-normal';
			unplannedIdling.className='line-through';
			plannedDown.className='line-through';
			plannedIdling.className='line-through';
			running.className='line-through';
		}else if(item ==='unplannedIdling'){
			unplanned.className='line-through';
			unplannedIdling.className='orange-normal';
			plannedDown.className='line-through';
			plannedIdling.className='line-through';
			running.className='line-through';
		}else if(item ==='plannedDown'){
			unplanned.className='line-through';
			unplannedIdling.className='line-through';
			plannedDown.className='blue-normal';
			plannedIdling.className='line-through';
			running.className='line-through';
		}else if(item ==='plannedIdling'){
			unplanned.className='line-through';
			unplannedIdling.className='line-through';
			plannedDown.className='line-through';
			plannedIdling.className='gray-normal';
			running.className='line-through';
		}else if(item ==='running'){
			unplanned.className='line-through';
			unplannedIdling.className='line-through';
			plannedDown.className='line-through';
			plannedIdling.className='line-through';
			running.className='green-normal';
		}else{
			unplanned.className='line-through';
			unplannedIdling.className='line-through';
			plannedDown.className='line-through';
			plannedIdling.className='line-through';
			running.className='line-through';
		}
	};
	let plannedDown = [];
	let unplannedDown = [];
	let unplannedIdling = [];
	let plannedIdling = [];
	let running = [];

	if(!isEmpty(data)){
		data.map((item) => {
			if(item.length>1){
				if (item[0].category==='UnplannedDown') {
					unplannedDown = item;
				}
				if (item[0].category==='UnplannedIdling') {
					unplannedIdling = item;
				}
				if (item[0].category==='PlannedDown') {
					plannedDown = item;
				}
				if (item[0].category==='PlannedIdling') {
					plannedIdling = item;
				}
				if (item[0].category==='Running') {
					running = item;
				}
			}else{
				if (item[0].category==='UnplannedDown') {
					unplannedDown = item;
				}
				if (item[0].category==='UnplannedIdling') {
					unplannedIdling = item;
				}
				if (item[0].category==='PlannedDown') {
					plannedDown = item;
				}
				if (item[0].category==='PlannedIdling') {
					plannedIdling = item;
				}
				if (item[0].category==='Running') {
					running = item;
				}
			}
		});
	}

	if(detailedData.length>0){
		detailedData.sort((a, b) => {
			if(a.count === b.count){
				let fa= String(a.reasonCode).toLowerCase(),	fb = String(b.reasonCode).toLowerCase();
				if (fa < fb) {
					return -1;
				}
				if (fa > fb) {
					return 1;
				}
				return 0;
			}
			return b.count - a.count;
		});
	}

	return (
		<HvDialog onClose={onClose} open={open} buttonTitle='Close' maxWidth='md' >
			<HvDialogContent indentContent >
				<HvDialogTitle >{thingName} {'('}
					<a id='unplanned' className='red-normal' onClick={() => showDowntimeDetails('unplanned',unplannedDown)}>{getCount(unplannedDown)}%</a>, 
					<a id='unplannedIdling' className='line-through' onClick={() => showDowntimeDetails('unplannedIdling',unplannedIdling)}>{getCount(unplannedIdling)}%</a>, 
					<a id='plannedDown' className='line-through' onClick={() => showDowntimeDetails('plannedDown',plannedDown)}>{getCount(plannedDown)}%</a>, 
					<a id='plannedIdling' className='line-through' onClick={() => showDowntimeDetails('plannedIdling',plannedIdling)}>{getCount(plannedIdling)}%</a>, 
					<a id='running' className='line-through' onClick={() => showDowntimeDetails('running',running)}>{getCount(running)}%</a>{')'}
				</HvDialogTitle>
				<HvTableContainer>
					<HvTable variant="default">
						<HvTableBody>
							{detailedData.length>0 && detailedData.map((val, index) => {
								return (
									<HvTableRow key={index}>
										<HvTableCell >{val.reasonCode}</HvTableCell>
										<HvTableCell >{msToTime(val.duration)}</HvTableCell>
										<HvTableCell >{val.description}</HvTableCell>
									</HvTableRow>
								);
							})
							}
						</HvTableBody>
					</HvTable>
				</HvTableContainer>
			</HvDialogContent>
		</HvDialog>
	);

	function getCount(item) {
		let count = 0;
		if (!isEmpty(item) && item.length !== null && item.length !== undefined && item.length > 1) {
			item?.map((val) => {
				count = count + Number(val.count);
			});
			return count.toFixed(2);
		} else if (item !=null && item[0] != undefined && item[0].count != undefined) {
			count = Number(item[0].count);
			return count.toFixed(2);
		} else {
			return 0;
		}
	}

	// function getReasonCodeValue(item,total){
	// 	let val=0;
	// 	if(item !==0 && total !==0){
	// 		let temp= 100/Number(total);
	// 		val=(Number(item)*Number(temp)).toFixed(1);
	// 	}
	// 	return val;
	// }

	function msToTime(item) {
		if(!isEmpty(item)){
			let ms=Number(item);
			let seconds = (ms / 1000).toFixed(1);
			let minutes = (ms / (1000 * 60)).toFixed(1);
			let hours = (ms / (1000 * 60 * 60)).toFixed(1);
			let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
			if (seconds < 60) {return seconds + ' Sec';}
			else if (minutes < 60) {return minutes + ' Min';}
			else if (hours < 24) {return hours + ' Hrs';}
			else {return days + ' Days';}
		}else{return 0.0 + ' Sec';}
	}
}

export default Popup;
