const initialState = {
	loading: false,
	downtimeReason: [],
	oeeValues: [],
	getimagebyid: [],
	getdevicedetails: [],
	sliceConfigurationParameters:[],
	machineAvailabilityInsights:[],
	downtimeStatus:[],
	reasonCodes:[],
	createDowntime:[],
	downtimeList:[],
	deleteDowntime:[],
	sensorValuesDropdown:[],
	assetTimeseries:[],
	senWindowTimestamps: []
};

const machineReducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'FETCH_DEVICE_DETAILS_REQUESTED':
		return {
			...state,
			loading: true,
			getdevicedetails: []
		};
	case 'FETCH_DEVICE_DETAILS_SUCCEEDED':
		return {
			...state,
			loading: false,
			getdevicedetails: payload
		};
	case 'FETCH_DEVICE_DETAILS_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_MACHINE_DOWNTIME_REASON_REQUESTED':
		return {
			...state,
			loading: true,
			downtimeReason: []
		};
	case 'FETCH_MACHINE_DOWNTIME_REASON_SUCCEEDED':
		return {
			...state,
			loading: false,
			downtimeReason: payload
		};
	case 'FETCH_MACHINE_DOWNTIME_REASON_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_MACHINE_OEE_CALCULATION_REQUESTED':
		return {
			...state,
			loading: true,
			oeeValues: []
		};
	case 'FETCH_MACHINE_OEE_CALCULATION_SUCCEEDED':
		return {
			...state,
			loading: false,
			oeeValues: payload
		};
	case 'FETCH_MACHINE_OEE_CALCULATION_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_IMAGE_BYID_REQUESTED':
		return {
			...state,
			loading: true,
			getimagebyid: []
		};
	case 'FETCH_IMAGE_BYID_SUCCEEDED':
		return {
			...state,
			loading: false,
			getimagebyid: payload
		};
	case 'FETCH_IMAGE_BYID_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_SLICE_CONFIGURATION_PARAMS_REQUESTED':
		return {
			...state,
			loading: true,
			sliceConfigurationParameters: []
		};
	case 'FETCH_SLICE_CONFIGURATION_PARAMS_SUCCEEDED':
		return {
			...state,
			loading: false,
			sliceConfigurationParameters: payload
		};
	case 'FETCH_SLICE_CONFIGURATION_PARAMS_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_MACHINE_AVAILABILITY_INSIGHTS_REQUESTED':
		return {
			...state,
			loading: true,
			machineAvailabilityInsights: []
		};
	case 'FETCH_MACHINE_AVAILABILITY_INSIGHTS_SUCCEEDED':
		return {
			...state,
			loading: false,
			machineAvailabilityInsights: payload
		};
	case 'FETCH_MACHINE_AVAILABILITY_INSIGHTS_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_DOWNTIME_STATUS_REQUESTED':
		return {
			...state,
			loading: true,
			downtimeStatus: []
		};
	case 'FETCH_DOWNTIME_STATUS_SUCCEEDED':
		return {
			...state,
			loading: false,
			downtimeStatus: payload
		};
	case 'FETCH_DOWNTIME_STATUS_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_REASONCODES_REQUESTED':
		return {
			...state,
			loading: true,
			reasonCodes: []
		};
	case 'FETCH_REASONCODES_SUCCEEDED':
		return {
			...state,
			loading: false,
			reasonCodes: payload
		};
	case 'FETCH_REASONCODES_FAILED':
		return {
			...state,
			loading: false
		};
	case 'CREATE_DOWNTIME_REQUESTED':
		return {
			...state,
			loading: true,
			createDowntime: []
		};
	case 'CREATE_DOWNTIME_SUCCEEDED':
		return {
			...state,
			loading: false,
			createDowntime: payload
		};
	case 'CREATE_DOWNTIME_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_DOWNTIME_LIST_REQUESTED':
		return {
			...state,
			loading: true,
			downtimeList: []
		};
	case 'FETCH_DOWNTIME_LIST_SUCCEEDED':
		return {
			...state,
			loading: false,
			downtimeList: payload
		};
	case 'FETCH_DOWNTIME_LIST_FAILED':
		return {
			...state,
			loading: false
		};
	case 'DELETE_DOWNTIME_REQUESTED':
		return {
			...state,
			loading: true,
			deleteDowntime: []
		};
	case 'DELETE_DOWNTIME_SUCCEEDED':
		return {
			...state,
			loading: false,
			deleteDowntime: payload
		};
	case 'DELETE_DOWNTIME_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_SENSOR_VALUES_DROPDOWN_REQUESTED':
		return {
			...state,
			loading: true,
			sensorValuesDropdown: []
		};
	case 'FETCH_SENSOR_VALUES_DROPDOWN_SUCCEEDED':
		return {
			...state,
			loading: false,
			sensorValuesDropdown: payload
		};
	case 'FETCH_SENSOR_VALUES_DROPDOWN_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_ASSET_TIME_SERIES_REQUESTED':
		return {
			...state,
			loading: true,
			assetTimeseries: []
		};
	case 'FETCH_ASSET_TIME_SERIES_SUCCEEDED':
		return {
			...state,
			loading: false,
			assetTimeseries: payload
		};
	case 'FETCH_ASSET_TIME_SERIES_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_SEN_WINDOW_TIMESTAMP_REQUESTED':
		return {
			...state,
			loading: true,
			senWindowTimestamps: []
		};
	case 'FETCH_SEN_WINDOW_TIMESTAMP_SUCCEEDED':
		return {
			...state,
			loading: false,
			senWindowTimestamps: payload
		};
	case 'FETCH_SEN_WINDOW_TIMESTAMP_FAILED':
		return {
			...state,
			loading: false
		};
	default:
		return {
			...state
		};
	}
};

export default machineReducer;
