import axios from 'axios';

async function callMapicsApi(method, data) {
	const headers = {
		'Content-Type': 'application/json',
		role_name: 'All',
		user_name: 'All',
		org_id: 0
	};

	if (method === 'post') {
		const response = await axios.post(
			process.env.REACT_APP_MAPICS_URL + '/mapics',
			data,
			{
				headers: headers
			}
		);
		return response.data;
	}
}

export const getFactoryDistributionMap = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getFactoryDistributionMap',
			params: {
				thingId: params.thingId
			},
			sortBy: 'thingId',
			ascOrder: false,
			cursor: 0,
			pageSize: 0
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getOEEValues = async (params) => {
	const data = [
		{
			apiName: 'event_g.getOEECalculationDetails',
			params: {
				thing_id: params.thingId,
				filter_datetime: params.filter_datetime,
				mode: params.mode,
				hour_mode_duration:params.hour_mode_duration,
				filter_start: params.filter_start,
				filter_end: params.filter_end
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const fetchHierarchyById = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getThingHierarchyByID',
			params: {
				thing_id: params.thingId
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getUserPreference = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getUserPreference',
			method: 'GET',
			requestParams: '/dashboard/api/v1/userpreference',
			header: {
				uid: params.uid
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const updateUserPreference = async (params) => {
	const data = [
		{
			apiName: 'midataapp.updateUserPreference',
			requestBody: params?.mapPosition !== undefined ? { mapPosition: params.mapPosition } : {
				preferredTimeperiod: {
					type: params.type,
					value: params.value
				}
			} ,
			method: 'POST',
			requestParams: '/dashboard/api/v1/userpreference',
			header: {
				uid: params.uid
			},
			exeOrder: 1
		},
		{
			apiName: 'midataapp.getUserPreference',
			method: 'GET',
			requestParams: '/dashboard/api/v1/userpreference',
			header: {
				uid: params.uid
			},
			exeOrder: 2
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getWindowTimeStamps = async (params) => {
	const data = [
		{
			apiName: 'event_g.getWindowTimestamps',
			params: {
				mode: params.mode,
				hour_mode_duration: params.hour_mode_duration,
				thing_id: params.thing_id,
				filter_datetime: params.filter_datetime,
				filter_start: params.filter_start,
				filter_end: params.filter_end
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getAdminCompany = async() => {
	const data = [
		{
			apiName: 'miadminapp.getAdminCompany'
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const fetchProductByThingId = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getProductByThingId',
			params: {
				thing_id: params.thingId
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const fetchEnterpriseProductByThingId = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getEnterpriseProductByThingId',
			params: {
				thing_id: params.thingId
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const fetchProductionVolumeDetails = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getProductionVolumeByProd',
			params: {
				mode: params.mode,
				filter_datetime: params.filter_datetime,
				hour_mode_duration: params.hour_mode_duration,
				filter_start: params.filter_start,
				filter_end: params.filter_end,
				prod_id: params.prod_id,
				thing_id: params.thing_id
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};