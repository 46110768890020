// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.custom-background {
    opacity: 1;
    .HvLogin-formContainer {
        height: 91.5vh !important;
    }
}

.root {
    top: 47px;
    left: 866px;
    width: 500px;
    height: 100%;
    /* background: #FBFCFC 0% 0% no-repeat padding-box; */
    margin: auto;
    padding-top: 130;
    .HvTypography-title2 {
        text-align: center;
        margin-bottom: 41px;
    };
}
 
.login-text {
    margin: 0 50px;
    padding-bottom: 20px;
    border-radius: 2px;
    .HvBaseInput-root {
        border: 1px solid #6C6B6B;
        border-radius: 2px;
      };
}

.login-btn {
    width: 90px;
    height: 32px;
    left: 360px;
    position: relative;
    /* background: #D67B19 0% 0% no-repeat padding-box; */
    border-radius: 2px;
    opacity: 1;
    /* .login-btn:Hover {
      background: #D67B19 0% 0% no-repeat padding-box;
    }; */
};

.lineStyle {
    background-color: #d35555;
    height: 1px;
    text-align: center !important;   
    margin-top: 17px;
    position: relative;
};

.red-btn {
    color:#D93135;
};



`, "",{"version":3,"sources":["webpack://./src/shared/Login/Login.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,SAAS;IACT,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,qDAAqD;IACrD,YAAY;IACZ,gBAAgB;IAChB;QACI,kBAAkB;QAClB,mBAAmB;IACvB,CAAA;AACJ;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,kBAAkB;IAClB;QACI,yBAAyB;QACzB,kBAAkB;MACpB,CAAA;AACN;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,qDAAqD;IACrD,kBAAkB;IAClB,UAAU;IACV;;QAEI;AACR,CAAA;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,6BAA6B;IAC7B,gBAAgB;IAChB,kBAAkB;AACtB,CAAA;;AAEA;IACI,aAAa;AACjB,CAAA","sourcesContent":["\n.custom-background {\n    opacity: 1;\n    .HvLogin-formContainer {\n        height: 91.5vh !important;\n    }\n}\n\n.root {\n    top: 47px;\n    left: 866px;\n    width: 500px;\n    height: 100%;\n    /* background: #FBFCFC 0% 0% no-repeat padding-box; */\n    margin: auto;\n    padding-top: 130;\n    .HvTypography-title2 {\n        text-align: center;\n        margin-bottom: 41px;\n    };\n}\n \n.login-text {\n    margin: 0 50px;\n    padding-bottom: 20px;\n    border-radius: 2px;\n    .HvBaseInput-root {\n        border: 1px solid #6C6B6B;\n        border-radius: 2px;\n      };\n}\n\n.login-btn {\n    width: 90px;\n    height: 32px;\n    left: 360px;\n    position: relative;\n    /* background: #D67B19 0% 0% no-repeat padding-box; */\n    border-radius: 2px;\n    opacity: 1;\n    /* .login-btn:Hover {\n      background: #D67B19 0% 0% no-repeat padding-box;\n    }; */\n};\n\n.lineStyle {\n    background-color: #d35555;\n    height: 1px;\n    text-align: center !important;   \n    margin-top: 17px;\n    position: relative;\n};\n\n.red-btn {\n    color:#D93135;\n};\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
