import { all, call, put, takeLatest } from 'redux-saga/effects';
import dashboardSaga from './dashboard';
import qualityAnalyticsSaga from './QualityAnalytics';
import machineSaga from './machine';
import TankMaster from './TankMaster';
import lineSaga from './line';
import assetAnalyticsSaga from './AssetAnalytics';
// function logger(action, payload) {
// 	console.log({ ACTION: action, PAYLOAD: payload });
// }

function workerWrapper(prefix, fetch) {
	function* worker({ payload } = {}) {
		try {
			// logger(`${prefix}_REQUESTED`, payload);
			const response = payload === undefined ? yield call(fetch) : yield call(fetch, payload);
			if (prefix === 'UPDATE_USER_PREFERENCE' && response && response[1] && response[1].resultSet) {
				const result = response[1].resultSet;
				yield put({ type: `${prefix}_SUCCEEDED`, payload: result });
			}
			// logger(`${prefix}_SUCCEEDED`, payload);			
			else if (response && response[0] && response[0].resultSet) {
				const result = response[0].resultSet;
				yield put({ type: `${prefix}_SUCCEEDED`, payload: result });
			}
			else {
				yield put({ type: `${prefix}_FAILED`, payload: [] });
			}
		} catch (error) {
			// logger(`${prefix}_FAILED`, error);
			yield put({ type: `${prefix}_FAILED`, payload: error.response });
		}
	}
	worker.fetch = fetch;
	worker.prefix = prefix;
	return worker;
}

export function takeLatestWrapper(prefix, fetch) {
	if (!fetch) {
		// console.log('Error function not provide for fetch');
		return false;
	}
	return takeLatest(`${prefix}_REQUESTED`, workerWrapper(prefix, fetch));
}

export default function* rootSaga() {
	yield all([dashboardSaga(), machineSaga(), qualityAnalyticsSaga(), lineSaga(), assetAnalyticsSaga(), TankMaster()]);
}
