import {HvButton,HvDialog,HvDialogActions,HvDialogContent,HvDialogTitle,HvDropdown,HvGrid,HvSnackbar,HvTable,HvTableBody,HvTableCell,HvTableContainer,
	HvTableHead,HvTableHeader,HvTableRow, HvTypography } from '@hitachivantara/uikit-react-core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Delete, Edit } from '@hitachivantara/uikit-react-icons';
import { ERROR_MESSAGE, convertTimeStamptoDate, isEmpty } from './Util';
import { createDowntime, deleteDownTime } from '../../api/machine'; 
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

let sorting = true;
function DailogBox({ insigtsData,thingId, editAdd, statusCodes, reasonCodes,maData,sliceCfgData, parentCallback }) {
	DailogBox.propTypes = {
		insigtsData: PropTypes.any.isRequired,
		editAdd: PropTypes.string.isRequired,
		reasonCodes: PropTypes.array.isRequired,
		statusCodes: PropTypes.array.isRequired,
		maData:PropTypes.object.isRequired,
		sliceCfgData:PropTypes.string.isRequired,
		thingId:PropTypes.any.isRequired,
		parentCallback: PropTypes.any
	};
	const [open, setOpen] = useState(false);
	const [edit, setEdit] = useState(false);
	const [maStatusList, setMaStatusList] = useState([]);
	const [maReasonCodesList, setMaReasonCodesList] = useState([]);
	const [tempItem,setTempItem] =useState([]);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [selectedReason, setSelectedReason] = useState([]);
	const [selectedStartDate, setSelectedStartDate] = useState([]);
	const [selectedEndDate, setSelectedEndDate] = useState([]);
	const [errMsg, setErrMsg] = useState('');
	const [error, setError] = useState();
	const [responseMessage,setResponseMessage] = useState();
	const [variant,setVariant]=useState();
	const [unPlannedData,setUnPlannedData]=useState([]);

	
	let machineName='';
	let errorFlag=false;
	let unPlanData=[];
	if (!isEmpty(sliceCfgData)) {
		let data=null;
		try {
			data = JSON.parse(sliceCfgData);
			if(data!==undefined && data.data!==undefined ){
				unPlanData=[];
				data.data.map((item) => {
					machineName=item.machineName;
					if (item.status === 'Running') {
					//skip it
					}else{
						unPlanData.push(item);
					}
				});
			}
		} catch (error) {
			//console.error('Exception in sliceCfgData & parsing==',error);
		}
	}
	let reasons=[];
	const todayDate=new Date();
	let myStartDate = new Date(todayDate - 5 * 60000);
	if(isEmpty(statusCodes) ||isEmpty(reasonCodes)){
		errorFlag=true;
	}else{
		reasons=[];
		reasons.push({id: 0, label:'NA'});
		reasonCodes.map((item)=>{
			reasons.push(item);
		});
	}

	useEffect(() => {
		selectStatus({ ...statusCodes[0], selected: true });
		selectReasonCode({ ...reasons[0], selected: true });
		onOpen(editAdd,null);
		setSelectedStartDate(myStartDate);
		setSelectedEndDate(todayDate);
		setError(false);
		setVariant('success');
		setResponseMessage('Downtime Created Successfully.');
		setUnPlannedData(unPlanData);
	}, []);
	const onClose = () => {
		setOpen(false);
		parentCallback(false);
	};

	const OnClickDeleteDowntime =() => {
		deleteDowntime(tempItem);
	};
	const OnClickCreateUpdateDowntime =() => {
		let params={};
		if(!isEmpty(tempItem)){
			params.downtimeId=tempItem.thingDowntimeId;
		}else{params.downtimeId=null;}
		if(selectedStatus ==='Planned Down' || selectedStatus ==='PlannedDown'){
			params.planned=true;
			params.status='Down';
		}else if(selectedStatus ==='Unplanned Down' || selectedStatus ==='UnplannedDown'){
			params.planned=false;
			params.status='Down';
		}else if(selectedStatus ==='Unplanned Idling' || selectedStatus ==='UnplannedIdling'){
			params.planned=false;
			params.status='Idle';
		}else if(selectedStatus ==='Planned Idling' || selectedStatus ==='PlannedIdling'){
			params.planned=true;
			params.status='Idle';
		}
		params.uid=maData.uid;
		if(selectedReason ==='NA'){
			params.reasonCode=null;
		}else{
			params.reasonCode=selectedReason;
		}

		if(moment(selectedStartDate, 'MMMM d, yyyy hh:mm:ss aa').valueOf() >= moment(selectedEndDate, 'MMMM d, yyyy hh:mm:ss aa').valueOf()){
			setErrMsg('Start Date & Time should not be greater than equal to End Date & Time.');
		}else{
			setErrMsg('');
			createOrUpdateDowntime(params);
		}
	};

	const onOpen = (k,item) => {
		setTempItem(item);
		if(k ==='editEdit' && item !=null){
			statusCodes.map((items) => {
				if(items.label ===item.status){
					selectStatus({ ...items, selected: true });
				}
			});

			reasons.map((items) => {
				if(items.label ===item.reasonCode){
					selectReasonCode({ ...items, selected: true });
				}
			});
			setSelectedStartDate(new Date(item.startTime));
			setSelectedEndDate(new Date(item.endTime));
		}
		setEdit(k);
		setOpen(true);
	};

	const selectStatus = (item) => {
		if(item===undefined){
			item={label: selectedStatus};
		}
		let updatedList =
		statusCodes && statusCodes.map((items) => {
			if(items.label === item.label){
				return { label: items.label, selected: true};
			}else{return items;}
		});
		setMaStatusList(updatedList);
		setSelectedStatus(item.label);
	};

	const selectReasonCode = (item) => {
		if(item===undefined){
			item={label: selectedReason};
		}
		let updatedList =
		reasons && reasons.map((items) => {
			if(items.label === item.label){
				return { label: items.label, selected: true};
			}else{return items;}
		});
		setMaReasonCodesList(updatedList);
		setSelectedReason(item.label);
	};

	const deleteDowntime = (params) => {
		deleteDownTime({
			id: params.thingDowntimeId
		}).then(() => {
			setError(true);
			setVariant('success');
			setResponseMessage('Id: '+params.thingDowntimeId+' Downtime Deleted Successfully.');
			setOpen(false);
		}).catch(() =>{
			setVariant('error');
			setError(true);
			setResponseMessage('There is an issue from backend system to Delete Downtime.');
			setOpen(false);
		});
	};

	const createOrUpdateDowntime = (params) => {
		createDowntime({
			thingId: thingId,
			state: params.status,
			startTime:  moment(selectedStartDate, 'MMMM d, yyyy hh:mm:ss aa').valueOf(),
			endTime: moment(selectedEndDate, 'MMMM d, yyyy hh:mm:ss aa').valueOf(),
			reasonCode: params.reasonCode,
			planned: params.planned,
			uid:params.uid,
			downtimeId: params.downtimeId
		}).then((res) => {
			let item = res[0].resultSet;
			let obj=JSON.parse(item);
			setError(true);
			setVariant('success');
			if(!isEmpty(params.downtimeId)){
				setResponseMessage('Id: '+params.downtimeId+' Downtime Updated Successfully.');
			}else{
				setResponseMessage('Id: '+obj.id+' Downtime Created Successfully.');
			}
			setOpen(false);
		}).catch(() =>{
			setVariant('error');
			setError(true);
			setResponseMessage('There is an issue from backend system to Create Downtime.');
			setOpen(false);
		});
	};

	const onChangeStartDate= (startDate) => {
		setSelectedStartDate(startDate);
		if(moment(startDate, 'MMMM d, yyyy hh:mm:ss aa').valueOf() >= moment(selectedEndDate, 'MMMM d, yyyy hh:mm:ss aa').valueOf()){
			setErrMsg('Start Date & Time should not be greater than equal to End Date & Time.');
		}else{
			setErrMsg('');
		}
	};

	const onChangeEndDate= (endDate) => {
		setSelectedEndDate(endDate);
		if(moment(selectedStartDate, 'MMMM d, yyyy hh:mm:ss aa').valueOf() >= moment(endDate, 'MMMM d, yyyy hh:mm:ss aa').valueOf()){
			setErrMsg('Start Date & Time should not be greater than equal to End Date & Time.');
		}else{
			setErrMsg('');
		}
	};
	
	const headerClick = (header) =>{
		let sortedData = [].concat(unPlannedData).sort((a, b) => {
			let fa= String(a[header]).toLowerCase(),
				fb = String(b[header]).toLowerCase();
		
			if (fa < fb) {
				return sorting?-1:1;
			}
			if (fa > fb) {
				return sorting?1:-1;
			}
			return 0;
		});
		setUnPlannedData(sortedData);
		sorting =!sorting;
	};

	const handleSnackBarClose = React.useCallback(() => {
		setOpen(false);
		parentCallback(true);
		setError(false);
	},[setError]);

	return (
		<>
			<HvSnackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={!!error}
				onClose={handleSnackBarClose}
				autoHideDuration={3000}
				variant={variant}
				label={responseMessage}
				showIcon
			/>;
			{edit === 'edit' && (
				<HvDialog onClose={onClose} open={open} buttonTitle='Close' maxWidth="md" fullWidth>
					<HvDialogContent indentContent>
						<HvTableContainer >
							<HvTable variant="default" style={{marginTop:'12px'}}>
								<HvTableHead>
									<HvTableRow>
										<HvTableHeader onClick={()=>headerClick('status')} sortable >Status</HvTableHeader>
										<HvTableHeader onClick={()=>headerClick('reasonCode')} sortable>Reason Code</HvTableHeader>
										<HvTableHeader onClick={()=>headerClick('startTime')} sortable>Start Time</HvTableHeader>
										<HvTableHeader onClick={()=>headerClick('endTime')} sortable>End time</HvTableHeader>
										<HvTableHeader style={{width:'10px'}}> </HvTableHeader>
										<HvTableHeader style={{width:'10px'}}> </HvTableHeader>
									</HvTableRow>
								</HvTableHead>
								<HvTableBody>
									{isEmpty(unPlannedData) && 
											<HvTableRow>
												<HvTableCell>No Data Found</HvTableCell>
												<HvTableCell></HvTableCell>
												<HvTableCell></HvTableCell>
												<HvTableCell></HvTableCell>
												<HvTableCell></HvTableCell>
												<HvTableCell></HvTableCell>
											</HvTableRow>
									}
									{unPlannedData.map((val, index) => {
										return (
											<HvTableRow key={index}>
												{val.status ==='Planned Down' &&
														<HvTableCell style={{color:'#82cfff'}}>{val.status}</HvTableCell>
												}
												{val.status ==='Unplanned Down' &&
														<HvTableCell style={{color:'#FF5E6C'}}>{val.status}</HvTableCell>
												}
												{val.status ==='Planned Idling' &&
														<HvTableCell style={{color:'gray'}}>{val.status}</HvTableCell>
												}
												{val.status ==='Unplanned Idling' &&
														<HvTableCell style={{color:'#E68C17'}}>{val.status}</HvTableCell>
												}
												{val.status != 'Unplanned Idling' && val.status !='Planned Down' && val.status != 'Unplanned Down' 
													&& val.status != 'Planned Idling' &&
														<HvTableCell>{val.status}</HvTableCell>
												}
												<HvTableCell>{val.reasonCode}</HvTableCell>
												<HvTableCell>{convertTimeStamptoDate(val.startTime)}</HvTableCell>
												<HvTableCell>{convertTimeStamptoDate(val.endTime)}</HvTableCell>  
												<HvTableCell>
													<Edit style={{ cursor: 'pointer' }} onClick={() => onOpen('editEdit',val)}/>
												</HvTableCell>
												<HvTableCell>
													<Delete style={{ cursor: 'pointer' }} onClick={() => onOpen('delete',val)} />
												</HvTableCell> 
											</HvTableRow>
										);
									})}
								</HvTableBody>
							</HvTable>
						</HvTableContainer>
					</HvDialogContent>
				</HvDialog>
			)}
			{edit === 'add' && (
				<HvDialog onClose={onClose} open={open} buttonTitle='Close' maxWidth="sm">
					<HvDialogContent indentContent>
						<HvTypography variant="title4" style={{padding:'14px 0px 15px 0px'}}>Add Machine Event</HvTypography>
						<div style={{paddingBottom:'15px'}}>Machine : <span style={{fontWeight:'600',fontSize: '13px'}}>{machineName}</span></div>
						{errorFlag &&(<h2 style={{color:'red'}}>{ERROR_MESSAGE}</h2>)}
						<form id="create-post" onSubmit={function Tl() {}}>
							<HvGrid container>
								<HvGrid item xs={12}>
									<HvDropdown style={{maxWidth:'340px', width:'320px'}} autoFocus aria-label="Single selection" onChange={selectStatus}
										values={maStatusList} label="Status" name="Status" required />
								</HvGrid>
								<HvGrid item xs={12}>
									<HvGrid container>
										<HvGrid item xs={6} style={{display:'grid'}}>
											<label>Start Time*</label>
											<DatePicker className='dateclass'
												selected={selectedStartDate}
												onChange={(date) => onChangeStartDate(date)}
												showTimeSelect
												timeFormat="HH:mm:ss"
												timeIntervals={1}
												timeCaption="time"
												dateFormat="yyyy-MM-dd hh:mm:ss aa"
											/>
										</HvGrid>
										<HvGrid item xs={6}>
											<HvGrid container>
												<HvGrid item xs={6} style={{display:'grid'}}>
													<label>End Time*</label>
													<DatePicker className='dateclass'
														selected={selectedEndDate}
														onChange={(date) => onChangeEndDate(date)}
														showTimeSelect
														timeFormat="HH:mm:ss"
														timeIntervals={1}
														timeCaption="time"
														dateFormat="yyyy-MM-dd hh:mm:ss aa"
													/> 
												</HvGrid>
											</HvGrid>
										</HvGrid>
									</HvGrid>
								</HvGrid>
								{!isEmpty(errMsg) && <div style={{color:'red',marginLeft:'32px'}}>{errMsg}</div>}
								<HvGrid item xs={12}>
									<HvDropdown autoFocus style={{maxWidth:'340px', width:'320px'}} label="Reason Code" name="Reason Code" aria-label="Single selection" 
										onChange={selectReasonCode} values={maReasonCodesList} required />
								</HvGrid>
							</HvGrid>
						</form>
					</HvDialogContent>
					<HvDialogActions>
						<HvButton onClick={OnClickCreateUpdateDowntime} variant="secondaryGhost">
							Save
						</HvButton>
						<HvButton autoFocus onClick={onClose} variant="secondaryGhost">
							Cancel
						</HvButton>
					</HvDialogActions>
				</HvDialog>
			)}
			{edit === 'editEdit' && (
				<HvDialog onClose={onClose} open={open} buttonTitle='Close' maxWidth="sm">
					<>
						<HvDialogContent indentContent>
							<HvTypography variant="title4" style={{padding:'14px 0px 15px 0px'}}>Edit Machine Event</HvTypography>
							<div style={{paddingBottom:'15px'}}>Machine : <span style={{fontWeight:'600',fontSize: '13px'}}>{machineName}</span></div>
							{errorFlag &&(<h4 style={{color:'red'}}>{ERROR_MESSAGE}</h4>)}
							<form id="create-post" onSubmit={function Tl() {}}>
								<HvGrid container>
									<HvGrid item xs={12}>
										<HvDropdown style={{maxWidth:'340px', width:'320px'}} autoFocus label="Status" name="Status" onChange={selectStatus}
											values={maStatusList} required />
									</HvGrid>
									<HvGrid item xs={12}>
										<HvGrid container>
											<HvGrid item xs={6} style={{display:'grid'}}>
												<label>Start Time*</label>
												<DatePicker className='dateclass'
													selected={selectedStartDate}
													onChange={(date) => onChangeStartDate(date)}
													showTimeSelect
													timeFormat="HH:mm:ss"
													timeIntervals={1}
													timeCaption="time"
													dateFormat="yyyy-MM-dd hh:mm:ss aa"
												/>
											</HvGrid>
											<HvGrid item xs={6}>
												<HvGrid container>
													<HvGrid item xs={6} style={{display:'grid'}}>
														<label>End Time*</label>
														<DatePicker className='dateclass'
															selected={selectedEndDate}
															onChange={(date) => onChangeEndDate(date)}
															showTimeSelect
															timeFormat="HH:mm:ss"
															timeIntervals={1}
															timeCaption="time"
															dateFormat="yyyy-MM-dd hh:mm:ss aa"
														/> 
													</HvGrid>
												</HvGrid>
											</HvGrid>
										</HvGrid>
									</HvGrid>
									{!isEmpty(errMsg) && <div style={{color:'red',marginLeft:'32px'}}>{errMsg}</div>}
									<HvGrid item xs={12}>
										<HvDropdown style={{maxWidth:'340px', width:'320px'}} autoFocus label="Reason Code" name="Reason Code" 
											onChange={selectReasonCode} values={maReasonCodesList} required />
									</HvGrid>
								</HvGrid>
							</form>
						</HvDialogContent>
						<HvDialogActions>
							<HvButton onClick={OnClickCreateUpdateDowntime} variant="secondaryGhost">
							Update
							</HvButton>
							<HvButton autoFocus onClick={onClose} variant="secondaryGhost">
							Cancel
							</HvButton>
						</HvDialogActions>
					</>
				</HvDialog>
			)}
			{edit === 'delete' && (
				<HvDialog onClose={onClose} open={open} buttonTitle='Close' maxWidth="md" fullWidth>
					<>
						<HvDialogContent indentContent>
							<HvDialogTitle>Delete Event On {insigtsData.thingName}</HvDialogTitle>
							<div id="hv-dialog-description" style={{ marginBottom: 10 }}>
								This will delete the event and the machine will be considered to be running normally in the time. Do you wnat to delete?
							</div>
						</HvDialogContent>
						<HvDialogActions>
							<HvButton onClick={OnClickDeleteDowntime} variant="secondaryGhost">
							Yes
							</HvButton>
							<HvButton autoFocus onClick={onClose} variant="secondaryGhost">
							No
							</HvButton>
						</HvDialogActions>
					</>
				</HvDialog>
			)}
		</>
	);
}

export default DailogBox;
