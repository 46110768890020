const initialState = {
	loading: false,
	alertcount: [],
	alerttabledata : [],
	healthDetailsTable : [],
	HealthValuesLoading: false
};

const lineReducare = (state = initialState, { type, payload }) => {
	
	switch (type) {
	case 'ALERT_COUNT_REQUESTED': 
		return {
			...state,
			loading: true,
			alertcount: []
		};
    
	case 'ALERT_COUNT_SUCCEEDED': 
	
		return {
			
			...state,
			loading: false,
			alertcount: payload
		};
    
	case 'ALERT_COUNT_FAILED': 
		return {
			...state,
			loading: false
		};
    
	case 'ALERT_TABLE_DATA_REQUESTED': 
		return {
			...state,
			loading: true,
			alerttabledata: []
		};
    
	case 'ALERT_TABLE_DATA_SUCCEEDED': 
	
		return {
			...state,
			loading: false,
			alerttabledata: payload
		};
    
	case 'ALERT_TABLE_DATA_FAILED': 
		return {
			...state,
			loading: false
		};
    
	case 'GET_DEVICE_HEALTH_DETAILS_REQUESTED': 
		return {
			...state,
			HealthValuesLoading: true,
			healthDetailsTable: []
		};
	case 'GET_DEVICE_HEALTH_DETAILS_SUCCEEDED': 
		return {
			...state,
			HealthValuesLoading: false,
			healthDetailsTable: payload
		};
	case 'GET_DEVICE_HEALTH_DETAILS_FAILED': 
		return {
			...state,
			loading: false
		};
	default:
		return {
			...state
		};
	}
};


export default lineReducare;