// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .headerStyle {
    font-weight: bold;
    text-align: left;
    font-size: 24px;
    white-space: nowrap;
  }
  
  .datePickerStyle {
    flex: 0 0 200px;
    display: block;
    justify-content: flex-end;
    padding: 8px;
  }
  
  .tabsContainerStyle {
    display: flex;
    align-items: center;
    width: 100%; 
    position: relative;
    margin-bottom: 16px;
  }
  
  .rightforwardMessageStyle {
    position: absolute;
    background-color: white;
    padding: 8px;
    border: 1px solid gray;
    border-radius: 4px;
    top: 35px;
    right: 0;
    transform: translateX(0);
    z-index: 2;
  }

  .leftforwardMessageStyle {
    position: absolute;
    background-color: white;
    padding: 8px;
    border: 1px solid gray;
    border-radius: 4px;
    top: 35px;
    right: 73%;
    transform: translateX(0);
    z-index: 2;
  }`, "",{"version":3,"sources":["webpack://./src/ma/Sensor/Sensor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,wBAAwB;IACxB,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,wBAAwB;IACxB,UAAU;EACZ","sourcesContent":[".containerStyle {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 16px;\n  }\n  \n  .headerStyle {\n    font-weight: bold;\n    text-align: left;\n    font-size: 24px;\n    white-space: nowrap;\n  }\n  \n  .datePickerStyle {\n    flex: 0 0 200px;\n    display: block;\n    justify-content: flex-end;\n    padding: 8px;\n  }\n  \n  .tabsContainerStyle {\n    display: flex;\n    align-items: center;\n    width: 100%; \n    position: relative;\n    margin-bottom: 16px;\n  }\n  \n  .rightforwardMessageStyle {\n    position: absolute;\n    background-color: white;\n    padding: 8px;\n    border: 1px solid gray;\n    border-radius: 4px;\n    top: 35px;\n    right: 0;\n    transform: translateX(0);\n    z-index: 2;\n  }\n\n  .leftforwardMessageStyle {\n    position: absolute;\n    background-color: white;\n    padding: 8px;\n    border: 1px solid gray;\n    border-radius: 4px;\n    top: 35px;\n    right: 73%;\n    transform: translateX(0);\n    z-index: 2;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
