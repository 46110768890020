import { createTheme } from '@hitachivantara/uikit-react-core';

const orange = '#D67B19';
const acce4 = '#6C6B6B';

export default createTheme({
	name:'hvUiKit',
	base:'ds5',
	palette: {
		common: {
			orange: orange,
			acce4: acce4
		}
	},
	hv: {
		typography: {
			normalText: {
				fontSize: '14px'
			},
			highlightText: {
				fontSize: '14px'
			},
			placeholderText: {
				fontSize: '14px'
			}
		}
	}
});
