import axios from 'axios';

async function callMapicsApi(method, data) {
	const headers = {
		'Content-Type': 'application/json',
		role_name: 'All',
		user_name: 'All',
		org_id: 1
	};
	if (method === 'post') {
		const response = await axios.post(
			process.env.REACT_APP_MAPICS_URL + '/mapics',
			data,
			{
				headers: headers
			}
		);
		return response.data;
	}
}

export const getDeviceDetails = async (params) => {
	const data = [
		{
			apiName: 'event_g.getDeviceDetails',
			params: {
				thingId: params.thingId,
				startDate: params.startDate,
				endDate: params.endDate
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getDowntimeReasonDetailsApi = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getDowntimeReasonChart',
			params: {
				thing_id: params.thingId,
				endTimestamp: params.endTimestamp,
				startTimestamp: params.startTimestamp
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getOeeCalculationSummaryApi = async (params) => {

	const data = [
		{
			apiName: 'event_g.getOEECalculationSummary',
			params: {
				thing_id: params.thingId,
				filter_datetime: params.filter_datetime,
				mode: params.mode,
				hour_mode_duration: params.hour_mode_duration,
				filter_start: params.filter_start,
				filter_end: params.filter_end
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getImageById = async (params) => {
	const data = [
		{
			apiName: 'midatadb.getImageById',
			params: {
				id: params.id
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//---------------Machine Availability----------START----------
//-----Get Slice Configuration --
export const getSliceConfigurationParameters = async (params) => {

	const data = [
		{
			apiName: 'midataapp.getSliceConfigurationParameters',
			requestBody: {
				sliceId:params.sliceId,
				thingId:params.thingId,
				timestampRequest: {
					endTimestamp: params.endTimestamp,
					startTimestamp:params.startTimestamp
				}
			},
			method: 'POST',
			requestParams: '/dashboard/api/v2/sliceconfig/',
			header: {
				uid: params.uid
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//Get Machine Availability - Insights 
export const getMachineAvailabilityInsights = async (params) => {

	const data = [
		{
			apiName: 'event_g.machineAvailabilityInsights',
			params: {
				thing_id: params.thingId,
				filter_datetime: params.filter_datetime,
				mode: params.mode,
				hour_mode_duration: params.hour_mode_duration,
				filter_start: params.filter_start,
				filter_end: params.filter_end
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//Get DowntimeStatus -Dropdown
export const getDowntimeStatusApi = async (params) => {
	const data = [
		{
			apiName: 'events.getDowntimeStatus',
			params: {
				thing_id: params.thing_id
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//Get getReasonCode -Dropdown 
export const getReasonCodesApi = async (params) => {

	const data = [
		{
			apiName: 'midataapp.getReasonCode',
			params: {},
			method: 'GET',
			requestParams: '/thingregistry/v1/reasonCodes/'+params.thingId+'/1',
			header: {
				uid: params.uid
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//Get createDownTime--API called for save/update button
export const createDowntime = async (params) => {
	const data = [
		{
			apiName: 'midataapp.createDownTime',
			method: 'POST',
			requestParams: '/dataingestion/v1/downtime',
			requestBody: {
				thingId: params.thingId,
				state: params.state,
				startTime: params.startTime,
				endTime: params.endTime,
				reasonCode: params.reasonCode,
				planned: params.planned,
				id:params.downtimeId
			},
			header: {
				uid: params.uid
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//Get edit the downtime--API called for edit button
export const getDownTimeList = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getDownTime',
			params: {
				id: params.id
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//Get Delete the downtime--API called for Delete button
export const deleteDownTime = async (params) => {
	const data = [
		{
			apiName: 'midataapp.deleteDownTime',
			params: {
				id: params.id
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//Get sensor value - dropdown
export const getSenValDropdownData = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getTable9',
			params: {
				thingid: params.thingid
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//Get getAssetTimeseries for data related to graph
export const getAssetTimeseries = async (params) => {
	const data = [
		{
			apiName: 'event_g.getAssetTimeseries',
			params: {
				id: params.id,
				startDate: params.startDate,
				endDate: params.endDate,
				timespanMode: params.timespanMode
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getSenWindowTimestamps = async (params) => {
	const data = [
		{
			apiName: 'event_g.getWindowTimestamps',
			params: {
				mode: params.mode,
				hour_mode_duration: params.hour_mode_duration,
				thing_id: params.thing_id,
				filter_datetime: params.filter_datetime,
				filter_start: params.filter_start,
				filter_end: params.filter_end
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};