import React, { useState, useEffect, useRef } from 'react';
import { HvSwitch, HvTypography } from '@hitachivantara/uikit-react-core';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from '@hitachivantara/uikit-react-icons';
import { updateUserPreference, getWindowTimestamps, toggleFeature } from '../../../actions';
import { formatDisplayDateRange, convertToStartEpoch, convertToEndEpoch, formatDate } from '../Util';
import PropTypes from 'prop-types';
import './DatePicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { getWindowTimeStamps } from '../../../api/dashboard';
import { isEmpty } from '../Util';
import _ from 'lodash';

function DatePicker({featureEnabled, uid, thingId, handleOptionClickToggledOff, pvOptions, pvSelectedOption, opOptions, opSelectedOption}) {
	DatePicker.propTypes = {
		featureEnabled: PropTypes.bool,
		uid: PropTypes.string,
		thingId:PropTypes.string,
		handleOptionClickToggledOff: PropTypes.func,
		pvOptions: PropTypes.arrayOf(PropTypes.string),
		pvSelectedOption: PropTypes.string,
		opOptions: PropTypes.arrayOf(PropTypes.string),
		opSelectedOption: PropTypes.string
	};

	const options = pvOptions || opOptions || ['Hour', 'Shift', 'Today', 'Week', 'Month', 'Custom']; 
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(pvOptions && pvOptions[3] ? pvOptions[3] : options[4]);
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [showSwitchLabel, setShowSwitchLabel] = useState(false);
	const [toggled, setToggled] = useState(true);
	const [customDateSelected, setCustomDateSelected] = useState(false);
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);
	const userPreference = useSelector((state) => state.dashboard.userPreference);
	const [errorMsg, setErrorMsg] = useState('');
	const API_ERROR_MESSAGE='Error from API.';
	const [preferredTimeperiod, setPreferredTimeperiod] = useState(null);

	const handleApply = () => {
		setShowDatePicker(false);
		setIsOpen(false);
		setSelectedOption(`${formatDisplayDateRange(dateRange[0].startDate)} - ${formatDisplayDateRange(dateRange[0].endDate)}`); 
		setCustomDateSelected(true);
		if(toggled === true){
			dispatchToUpdateUserPreference({ type: 'range', value: convertToRangeFormat(`${formatDisplayDateRange(dateRange[0].startDate)} - ${formatDisplayDateRange(dateRange[0].endDate)}`), uid: uid });
			dispatch(toggleFeature());
		} else if (toggled === false) {
			getWindowTimeStamps({
				mode: 'Custom',
				hour_mode_duration: 1,
				thing_id: thingId,
				filter_datetime: Date.now(),
				filter_start: convertToStartEpoch(`${formatDisplayDateRange(dateRange[0].startDate)} - ${formatDisplayDateRange(dateRange[0].endDate)}`),
				filter_end: convertToEndEpoch(`${formatDisplayDateRange(dateRange[0].startDate)} - ${formatDisplayDateRange(dateRange[0].endDate)}`)
			})
				.then((res) => {
					const data = res[0].resultSet; 
					handleOptionClickToggledOff({
						mode: 'Custom',
						hour_mode_duration: 1,
						thing_id: thingId,
						filter_datetime: Date.now(),
						filter_start: convertToStartEpoch(`${formatDisplayDateRange(dateRange[0].startDate)} - ${formatDisplayDateRange(dateRange[0].endDate)}`),
						filter_end: convertToEndEpoch(`${formatDisplayDateRange(dateRange[0].startDate)} - ${formatDisplayDateRange(dateRange[0].endDate)}`),
						wts: data 
					});
				})
				.catch(() => {
					setErrorMsg(API_ERROR_MESSAGE);
				});
		}   
	};

	const handleCancel = () => {
		setShowDatePicker(false);
		setIsOpen(false);
	};

	const handleSelect = (ranges) => {
		setDateRange([ranges.selection]);
	};

	useEffect(() => {
		if(_.isEqual(userPreference.preferredTimeperiod, preferredTimeperiod) && (thingId !== null || thingId !== undefined)){
			return;
		}
		if (!isEmpty(userPreference) && userPreference?.preferredTimeperiod?.type === 'interval') {
			setPreferredTimeperiod((thingId !== null && thingId !== undefined) ? userPreference.preferredTimeperiod : null);
			setCustomDateSelected(false);
			setSelectedOption(userPreference?.preferredTimeperiod?.value);
			dispatchToGetWindowTimestamps({ mode: userPreference?.preferredTimeperiod?.value === 'Today' ? 'Day' : userPreference?.preferredTimeperiod?.value, hour_mode_duration: 1, thing_id: thingId, filter_datetime: Date.now(), filter_start: 0 , filter_end: 0 });
		} else if (!isEmpty(userPreference) && userPreference?.preferredTimeperiod?.type === 'range') {
			setPreferredTimeperiod((thingId !== null && thingId !== undefined) ? userPreference.preferredTimeperiod : null);
			setCustomDateSelected(true);
			const rawValue = userPreference?.preferredTimeperiod?.value;
			if (rawValue.includes(',')) {
				const [startDate, endDate] = rawValue
					.replace('[', '')
					.replace(']', '')
					.split(',');
				const formattedStartDate = new Date(startDate).toLocaleDateString();
				const formattedEndDate = new Date(endDate).toLocaleDateString();
				setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
				dispatchToGetWindowTimestamps({ mode: 'Custom', hour_mode_duration: 1, thing_id: thingId, filter_datetime: Date.now(), filter_start: convertToStartEpoch(`${formattedStartDate} - ${formattedEndDate}`) , filter_end: convertToEndEpoch(`${formattedStartDate} - ${formattedEndDate}`) });
			}           
		} 
		else {  
			setPreferredTimeperiod(null);
			dispatchToGetWindowTimestamps({ mode: 'Month', hour_mode_duration: 1, thing_id: thingId, filter_datetime: Date.now(), filter_start: 0 , filter_end: 0 });
		}
	}, [userPreference, thingId]);

	const dispatchToGetWindowTimestamps = (params) => {
		if (params.thing_id !== null && params.thing_id !== undefined) {
			dispatch(
				getWindowTimestamps(params)
			);
		}
	};

	const dispatchToUpdateUserPreference = (params) => {
		dispatch(
			updateUserPreference(params)
		);
	};  

	const handleOptionClick = (type, selectedOption) => {
		if (toggled === true && type === 'interval') {
			setCustomDateSelected(false);
			dispatchToUpdateUserPreference({ type: 'interval', value: selectedOption, uid: uid });
			dispatch(toggleFeature());
		} else if (toggled === true && type === 'range') {
			dispatchToUpdateUserPreference({ type: 'range', value: convertToRangeFormat(selectedOption), uid: uid });
			dispatch(toggleFeature());
		} else if (toggled === false && type === 'interval') {
			setCustomDateSelected(false);
			getWindowTimeStamps({
				mode: selectedOption === 'Today' ? 'Day' : selectedOption,
				hour_mode_duration: 1,
				thing_id: thingId,
				filter_datetime: Date.now(),
				filter_start: 0,
				filter_end: 0
			})
				.then((res) => {
					const data = res[0].resultSet; 
					handleOptionClickToggledOff({
						mode: selectedOption === 'Today' ? 'Day' : selectedOption,
						hour_mode_duration: 1,
						thing_id: thingId,
						filter_datetime: Date.now(),
						filter_start: 0,
						filter_end: 0,
						wts: data 
					});
				})
				.catch(() => {
					setErrorMsg(API_ERROR_MESSAGE);
				});
		}
	};

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
		setShowDatePicker(selectedOption === 'Custom');
	};

	const handleOptionSelect = (option) => {
		if (option === 'Custom') {
			setToggled(toggled);
			setShowDatePicker(true);
			setIsOpen(true);
		} else if(option === 'Hour' || option === 'Shift' || option === 'Today' || option === 'Week' || option === 'Month') {
			setShowDatePicker(false);
			setIsOpen(false);
			setToggled(toggled);
			setSelectedOption(option);
			setCustomDateSelected(false);
			handleOptionClick('interval',option);
		}   
	};

	function convertToRangeFormat(dateRange) {
		const [startDateStr, endDateStr] = dateRange.split(' - ');

		const startDate = new Date(startDateStr);
		const endDate = new Date(endDateStr);

		const formattedStartDate = formatDate(startDate);
		const formattedEndDate = formatDate(endDate);

		return `[${formattedStartDate},${formattedEndDate}]`;
	}

	const getFormattedOptionLabel = (option) => {
		if ((pvSelectedOption === 'Hour' || pvSelectedOption === 'Custom') && (toggled === true || toggled === false)) {
			return 'This Month';
		} else if ((opSelectedOption === 'Shift' || opSelectedOption === 'Day' || opSelectedOption === 'Week') && (toggled === true || toggled === false)) {
			return 'This Month';
		} else if (option === 'Hour' || option === 'Shift' || option === 'Week' || option === 'Month') {
			return `This ${option}`;
		} else {
			return `${option}`;
		}
	};

	const dropdownOptionsStyle = {
		top: selectedOption === 'Custom' && (isOpen || showDatePicker) ? '98%' : '100%'
	};

	useEffect(() => {
		setToggled(true);
	},[featureEnabled]);

	const handleSwitchChange = () => {
		if (toggled === false) {
			handleOptionClickToggledOff({});
		}
		setToggled(!toggled);
	};

	const dropdownContainerRef = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
				setShowDatePicker(false);
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleOutsideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);   

	return (
		<div id="datePicker">
			<div style={{ paddingRight: '0px', paddingLeft: '0px' }}>
				<div style={{display:'flex'}}>
					<p style={{fontFamily: 'arial', fontSize: '10px', fontWeight: 'bold',minHeight:28,display:'flex',justifyContent:'center',alignItems:'center'}}>Date & Range</p>
					{showSwitchLabel && <div className="switch-label">Apply and save to all dashboards</div>}
				</div>
				{!isEmpty(errorMsg) && (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						<HvTypography style={{ fontSize: '14px', color: 'red' }}>{errorMsg}</HvTypography>
					</div>
				)}
				<div className={'containerStyle'} >
					<div ref={dropdownContainerRef} className={`custom-dropdown-container ${isOpen || showDatePicker ? 'open' : ''}`}  style={{ marginRight: '8px' }}>
						<div className="custom-dropdown" onClick={toggleDropdown}>
							<span className="dropdown-content">{getFormattedOptionLabel(selectedOption)}</span>
							<span className="dropdown-icon">
								<Calendar iconSize="S" role="presentation" />
							</span>
						</div>
						{isOpen && (
							<div className="dropdown-options" style={dropdownOptionsStyle}>
								<div className="options-list">
									{options.map((option, index) => (
										<div
											key={index}
											className={`dropdown-option ${selectedOption === option || (customDateSelected && option === 'Custom') ? 'selected' : ''}`}
											onClick={() => handleOptionSelect(option)}
										>
											{option}
										</div>
									))}
								</div>
							</div>
						)}
						{showDatePicker && (
							<div className="popup-container">
								<div >
									<div className="popup">
										<DateRangePicker
											ranges={dateRange}
											onChange={handleSelect}
											months={2}
											direction="horizontal"
											maxDate={new Date()} 
										/>
										<div className="date-range-picker-actions">
											<div style={{ textAlign: 'center' }}>
												<button onClick={handleApply}>Apply</button>
												<button onClick={handleCancel}>Cancel</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="switch-container">
						<div className="switch-wrapper" onMouseEnter={() => setShowSwitchLabel(true)} onMouseLeave={() => setShowSwitchLabel(false)}>
							<HvSwitch
								checked={toggled}
								onChange={handleSwitchChange}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DatePicker;