import { HvDropdown, HvTypography } from '@hitachivantara/uikit-react-core';
import { HvVizProvider } from '@hitachivantara/uikit-react-viz';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import DatePicker from '../shared/components/DatePicker/DatePicker';
import { getQualityKPI, getQualityProdDropdown } from '../actions';
import LineBarChart from '../shared/components/Charts/LineBarChart';
import Loading from '../shared/Loading/Loading';
import '../App.css';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from '../shared/components/Util';

let dataList = [];

function QualitybyTrending({ uid }) {
	QualitybyTrending.propTypes = {
		uid: PropTypes.string.isRequired
	};

	const options = {
		responsive: true,
		interaction: {
			mode: 'index',
			intersect: true
		},
		stacked: true,
		plugins: {
			legend: {
				display: true,
				with: 800,
				labels: {
					boxWidth: 10,
					boxHeight: 10,
					with: 800
				}
			},
			datalabels: {
				display: false
			},
	
			tooltip: {
				cornerRadius: 4,
				backgroundColor: '#FFFFFF',
				titleColor: 'black',
				callbacks:{
					label: function(context){
						if(context.datasetIndex === 0){
							const label = ' Date';
							const value = labels[context.dataIndex];
							return `${label}:${value}`;
						}
						else if (context.datasetIndex === 1){
							const label = ' Defects(Count)';
							const value = defectsCount[context.dataIndex];
							return `${label}:${value}`;
						}
						return '';
					}
				},
				titleFont: {
					family: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
					display: false,
					weight: 0,
					size: 0,
					style: 'normal'
				},
				xAlign: 'left',
				yAlign: 'top',
				width: '100px',
				height: '25px',
				bodyColor: 'black',
				bodyFont: { size: 14},
				boxWidth: 14,
				boxHeight: 1
			}
		},
		scales: {
			y: {
				type: 'linear',
				display: true,
				position: 'left',
				title:{
					display: true,
					text: 'Defects Count'
				}
			},
			x: {
				barPercentage: 0.1,
				stacked: true,
				grid: {
					display: false
				}
			}
		}
	};

	const location = useLocation();
	const [qualityKPIList, setQualityKPIList] = useState([]);
	const [qualityDataList, setQualityDataList] = useState([]);
	const qualityKpiData = useSelector((state) => state.qualityAnalytics.qualityKpiData);
	const qualityProdDropdown = useSelector((state) => state.qualityAnalytics.qualityProdDropdown);
	const userPreference = useSelector((state) => state.dashboard.userPreference);
	const windowTimestamps = useSelector((state) => state.dashboard.windowTimestamps);
	const featureEnabled = useSelector((state) => state.dashboard.featureEnabled);
	const [thingId, setThingId] = useState(null);
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.qualityAnalytics.loadingTrending);
	var defectsCount = qualityKPIList && qualityKPIList.filter((item) => (item.status === 'critical' || item.status === 'warning' || item.status === 'normal')).map((item) => item.count);
	const [toggledOffData, setToggledOffData] = useState([]);

	const handleOptionClickToggledOff = (params) => {
		setToggledOffData({ ...params });
	};
	
	useEffect(() => {
		if (location.state !== null) {
			setThingId(+location.state?.qaFactory?.thingId);
		}
	}, [location]);

	useEffect(() => {
		if (thingId !== null && thingId !== undefined) {
			dispatchToGetProdDropdownData();
		}
	}, [thingId]);

	const dispatchToGetProdDropdownData = () => {
		dispatch(
			getQualityProdDropdown({
				thingId
			})
		);
	};

	useEffect(() => {
		if (qualityProdDropdown.length > 0) {
			let latData = qualityProdDropdown.map((item) => {
				var obj = { ...item };
				obj.id = item.product_id;
				obj.label = item.product_name;
				return obj;
			}, []);
			dataList = [{ id: 0, label: 'All Products' }, ...latData];
			selectTrendingProduct({ ...dataList[0], selected: true });
		} else {
			setQualityDataList([]);
		}
	}, [qualityProdDropdown]);

	useEffect(() => {
		if (qualityKpiData.length == 0) {
			setQualityKPIList([]);
			return;
		}
		if (qualityKpiData) {
			setQualityKPIList(qualityKpiData);
		}

	}, [qualityKpiData]);

	const selectTrendingProduct = (item) => {
		if (item === undefined) {
			setQualityDataList([...qualityDataList]);
			return;
		}
		let updatedList = dataList && dataList.map((items) => {
			return (items.product_id === item.product_id) ? item : items;
		});
		setQualityDataList(updatedList);
		dispatchToGetQualityKPIlist(item);
	};

	useEffect(() => {
		if (windowTimestamps.length > 0 && dataList.length > 0) {
			dispatchToGetQualityKPIlist(dataList[0]);
		}
	}, [windowTimestamps, toggledOffData]);

	const dispatchToGetQualityKPIlist = (params) => {
		if (windowTimestamps.length > 0 && isEmpty(toggledOffData)) {
			if (userPreference?.preferredTimeperiod?.type === 'interval') {
				dispatch(
					getQualityKPI({
						productId: (params.id),
						defectId: 0,
						thingId: thingId,
						endTime: windowTimestamps[0]?.end_datetime_ep,
						startTime: windowTimestamps[0]?.start_datetime_ep,
						mode: userPreference?.preferredTimeperiod?.value?.toLowerCase()
					})
				);
			} else if (userPreference?.preferredTimeperiod?.type === 'range') {
				dispatch(
					getQualityKPI({
						productId: (params.id),
						defectId: 0,
						thingId: thingId,
						endTime: windowTimestamps[0]?.end_datetime_ep,
						startTime: windowTimestamps[0]?.start_datetime_ep,
						mode: '' //For Custom mode pass '' 
					})
				);
			}
		} else if (!isEmpty(toggledOffData)) {
			if (toggledOffData.mode === 'Custom') {
				dispatch(
					getQualityKPI({
						productId: (params.id),
						defectId: 0,
						thingId: thingId,
						endTime: toggledOffData.wts[0]?.end_datetime_ep,
						startTime: toggledOffData.wts[0]?.start_datetime_ep,
						mode: ''
					})
				);
			} else {
				dispatch(
					getQualityKPI({
						productId: (params.id),
						defectId: 0,
						thingId: thingId,
						endTime: toggledOffData.wts[0]?.end_datetime_ep,
						startTime: toggledOffData.wts[0]?.start_datetime_ep,
						mode: toggledOffData.mode === 'Day' ? 'today' : toggledOffData.mode.toLowerCase()
					})
				);
			}
			
		}
	};

	const labels = qualityKPIList && qualityKPIList.map((item) => moment.utc(Number(item.label)).format('YYYY-MM-DD HH:mm:ss'));
	const trendingApiData = {
		labels,
		datasets: [
			{
				label: 'Average',
				type: 'line',
				data: qualityKPIList && qualityKPIList.map((item) => item.avgValue),
				borderColor: 'rgb(110, 175, 255)',
				backgroundColor: 'rgb(110, 175, 255)',
				barThickness: '10',
				categoryPercentage: '2'
			},
			{
				label: 'Critical',
				data: qualityKPIList && qualityKPIList.filter((item) => item.status === 'critical').map((item) => item.count),
				borderColor: 'rgb(255, 82, 82)',
				backgroundColor: 'rgb(255, 82, 82)',
				yAxisID: 'y',
				barThickness: '10',
				categoryPercentage: '5'
			},
			{
				label: 'Warning',
				data: qualityKPIList && qualityKPIList.filter((item) => item.status === 'warning').map((item) => item.count),
				borderColor: 'rgb(230, 140, 23)',
				backgroundColor: 'rgb(230, 140, 23)',
				yAxisID: 'y',
				barThickness: '10',
				categoryPercentage: '5'
			},
			{
				label: 'Non-Critical',
				data: qualityKPIList && qualityKPIList.filter((item) => item.status === 'normal').map((item) => item.count),
				backgroundColor: 'rgb(99, 166, 33)',
				yAxisID: 'y',
				barThickness: '10',
				categoryPercentage: '5'
			}
		]
	};

	if (thingId !== null && thingId !== undefined) {
		return (
			<HvVizProvider>
				<HvTypography variant="title3" className="titleBox" style={{ height: '530px' }}>
					<div className="titleContentCss"> Quality Trending
						<div style={{ display: 'flex', marginLeft: '10px' , paddingTop:'5px'}}>
							<DatePicker featureEnabled={featureEnabled} uid={uid} thingId={thingId} handleOptionClickToggledOff={handleOptionClickToggledOff} />
						</div>
					</div>
					<div className="titleContentCss">
						<HvDropdown
							aria-label="Single selection"
							id="dropdown7"
							style={{ width: '200px' }}
							onClick={() => { }}
							values={qualityDataList}
							onChange={selectTrendingProduct}
						/>
					</div>

					<div className='chartBox' style={{ marginTop:'5px'}}>
						{qualityKpiData && (
							<LineBarChart data={trendingApiData} loading={loading} options={options} />
						)}
					</div>
				</HvTypography>
			</HvVizProvider>
		);
	} else {
		return <Loading />;
	}
}

export default QualitybyTrending;
