import moment from 'moment';

export const ERROR_MESSAGE='There is an issue from backend system.';
export const  isNotEmpty = (value) =>{
	let flag = false;
	if (value === null || value === undefined || value === '') {
		flag = false;
	} else if (value.length === 0) {
		flag = false;
	} else {
		flag = true;
	}
	return flag;
};

export const  isEmpty = (value) =>{
	let flag = true;
	if (value === null || value === undefined || value === '') {
		flag = true;
	} else if (value != null && value != undefined && value != '' && Object.keys(value).length !== 0) {
		if (value != '') {
			if (value.length != 0) {
				flag = false;
			} else {
				flag = true;
			}
		} else {
			flag = true;
		}
	}
	return flag;
};


export const  isEmptyReturnZero = (value) =>{
	let val = 0;
	if (value == null || value == undefined) {
		val = 0;
	} else if (value != null && value != undefined) {
		if (value != '') {
			if (value != 0) {
				val = value;
			} else {
				val = 0;
			}
		} else {
			val = 0;
		}
	}
	return val;
};

export const checkBarStatus = (item) => {
	let status = '';
	if (
		item.value <= item.lower_critical_threshold ||
  item.value >= item.upper_critical_threshold
	) {
		status = 'Critical';
	} else if (
		item.value <= item.lower_warning_threshold &&
  item.value > item.lower_critical_threshold
	) {
		status = 'Warning';
	} else if (
		item.value >= item.upper_warning_threshold &&
  item.value < item.upper_critical_threshold
	) {
		status = 'Warning';
	} else {
		status = 'Healthy';
	}
	return status;
};

export const colors = {
	red: '#FF5E6C',
	green: '#63A621',
	orange: '#E68C17',
	blue: '#82cfff',
	gray: '#808080'
};

export function isFloat(n){
	return Number(n) === n && n % 1 !== 0;
}

export function newDate(days) {
	return moment(days).format('MMMM Do YYYY, h:mm:ss a');
}
  
export function newDateString(days) {
	return moment(days).format('MMMM Do YYYY, h:mm:ss a');
}
  
export function parseISODate(str) {
	return moment(str).format('MMMM Do YYYY, h:mm:ss a');
}

export function formatStringToDate(str) {
	return str?moment(str).format('YYYY-MM-DD h:mm:ss'):'';
}
export function convertTimeStamptoDate(echo){
	return moment(new Date(echo)).format('YYYY-MM-DD HH:mm');
}

export const formatDisplayDateRange = (date) => {
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear();
	return `${month}/${day}/${year}`;
};

export const convertToStartEpoch = (inputRange) => {
	let startEpoch = 0;
	if (inputRange !== '') {
		const [startDateStr] = inputRange.split(' - ');
		const [startMonth, startDay, startYear] = startDateStr.split('/');
		const startDate = new Date(Date.UTC(startYear, startMonth - 1, startDay));
		startEpoch = startDate.getTime();
	}
	return startEpoch;
};

export const convertToEndEpoch = (inputRange) => {
	let endEpoch = 0;
	if (inputRange !== '') {
		const [, endDateStr] = inputRange.split(' - ');
		const [endMonth, endDay, endYear] = endDateStr.split('/');
		const endDate = new Date(Date.UTC(endYear, endMonth - 1, endDay)); 
		endEpoch = endDate.getTime();
	}
	return endEpoch;
};

export const formatDate = (date) => {
		
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	const hours = '00';
	const minutes = '00';
	const seconds = '00';

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const hHVDATE = (hvDate) => {
	const date = new Date(hvDate);
	date.setSeconds(0);
	date.setMilliseconds(0);
	const roundedTimestamp = date.getTime();

	return roundedTimestamp;
};

export const upArrow = (color, width = '25px', height = '30px') => {
	return(
		<svg fill={color} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
			width={width} height={height} viewBox="0 0 60.731 60.731" xmlSpace="preserve" style={{padding: '5px'}}>
			<g>
				<g>
					<polygon points="30.366,0 0.625,29.735 17.998,29.735 18.003,60.731 42.733,60.729 42.733,29.735 60.107,29.735 		"/>
				</g>
			</g>
		</svg>
	);
};

export const downArrow = (color, width = '25px', height = '30px') => {
	return(
		<div style={{transform: 'rotate(180deg)'}}>
			<svg fill={color} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
				width={width} height={height} viewBox="0 0 60.731 60.731" xmlSpace="preserve" style={{padding: '5px'}}>
				<g>
					<g>
						<polygon points="30.366,0 0.625,29.735 17.998,29.735 18.003,60.731 42.733,60.729 42.733,29.735 60.107,29.735 		"/>
					</g>
				</g>
			</svg>
		</div>
	);
};

export const convertToLocalTimeZone = (createdDate) => {
	let stillUtc =  moment.utc(createdDate);
	let local = moment(stillUtc).local().valueOf();
	return local;
};

export const convertToUTCTimeZone = (createdDate) => {
	let utc = moment(createdDate).utc().format('yyyy-MM-DD HH:mm:ss');
	return utc;
};

export const convertToUTCTimeZoneInMs = (ms) => {
	let time=1000*60*60*5.5;
	return (Number(ms)- Number(time));
};

export const minimizeName = (val) => {
	if(!isEmpty(val) && val.length>15){
		return val.substring(0,18)+'..';
	}else{
		return val;
	}
};

export const getValueasString = (val) => {
	if(val!==null && val!==undefined && val!==''){
		return val+'';
	}else{
		return '';
	}
};

export const getMapicsUrl = () => {
	let mapicsUrl = 'https://mapics.qa.he.az.devmfi.net';
	if(location.hostname === 'localhost'){ // Local Development
		mapicsUrl = 'https://mapics.qa.he.az.devmfi.net';
	}
	else if(location.hostname === 'react.dev.demo.azure.devmfi.net'){ // Azure Dev
		mapicsUrl = 'https://mapics.qa.he.az.devmfi.net';
	}
	else if(location.hostname === 'react.qa.he.az.devmfi.net'){ // QA & UAT 
		mapicsUrl = 'https://mapics.qa.he.az.devmfi.net';
	}
	else if(location.hostname === 'react.qa.he.devmfi.net'){ // Jeffcity Dev
		mapicsUrl = 'https://mapics.qa.he.devmfi.net';
	}
	else if(location.hostname === 'react.performance.devmfi.net'){ // Performance
		mapicsUrl = 'https://mapics.performance.devmfi.net';
	}
	return mapicsUrl;
};

