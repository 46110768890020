import React from 'react';
import PropTypes from 'prop-types';
import './KpiCard.css';
import { upArrow ,downArrow } from './Util';
import { HvGrid } from '@hitachivantara/uikit-react-core';

const KpiCard = ({ label, daysOrHours, status }) => {

	const [value, unit] = daysOrHours.split(' ');

	const getArrowColors = (status) =>{
		switch(status){
		case 'MTBFup':
			return upArrow('rgb(99, 166, 33)' ,'70px' ,'70px');
		case 'MTTRdown':
			return downArrow('rgb(231, 120, 77)','70px' ,'70px');
		case 'MTTRup':
			return upArrow('rgb(255, 82, 82)','70px' ,'70px');
		case '':
			return upArrow('rgb(255, 82, 82)','70px' ,'70px');
		default: 
			return upArrow('rgb(99, 166, 33)','70px' ,'70px');
		}
	};

	return (
		<div className='KPIcard'>
			<HvGrid container>
				<HvGrid item xs ={8}>
					<div className='KPIcard-header'>{label}</div>
					<div className='KPIcard-content'>
						<div className='value' style={{ marginRight: '8px' }}>{value}</div>
						<div className='unit'>{unit}</div>
						
					</div>
				</HvGrid>
				<HvGrid item xs ={4}>
					{getArrowColors(status)}
				</HvGrid>
			</HvGrid>
		</div>
	);
};

KpiCard.propTypes = {
	label: PropTypes.string.isRequired,
	daysOrHours: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]).isRequired,
	status: PropTypes.oneOf(['MTBFup', 'MTTRup', 'MTBFdown', 'MTTRdown']).isRequired
};

export default KpiCard;
