import React from 'react';
import { HvLoading } from '@hitachivantara/uikit-react-core';

const Loading = () => {

	const loadingPanel = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-around',
				alignItems: 'center',
				paddingTop: '7%'
			}}>
			<HvLoading />
		</div>
	);

	return loadingPanel;
};

export default Loading;
