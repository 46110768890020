// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KPIcard {
  width: 210px;
  border: 1.5px solid #adb5bd;
  border-top: 3px solid rgb(255, 82, 82);
  border-radius: 6px;
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  min-height: 95px;
}
  
.KPIcard-header {
  font-size: 1.5em;
  color: black;
  padding-bottom: 8px;
  font-weight: bold;
}
  
.KPIcard-content {
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.value {
  font-size: 2.5em;
}

.unit {
  font-size: 1.5em;
  padding-top: 8px;
}
  
@media screen and (min-width: 1270px) {
  #MTBF {
    padding-left : 8%;
  }

  #MTTR {
    padding-left : 10%;
  }
}

@media screen and (min-width: 1920px){
  #MTBF {
    padding-left : 5%;
    max-width: 40%;
  }
  
  #MTTR {
    padding-left : 5%;
    max-width: 40%;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/KpiCard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2BAA2B;EAC3B,sCAAsC;EACtC,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;AACF","sourcesContent":[".KPIcard {\n  width: 210px;\n  border: 1.5px solid #adb5bd;\n  border-top: 3px solid rgb(255, 82, 82);\n  border-radius: 6px;\n  background-color: white;\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  min-height: 95px;\n}\n  \n.KPIcard-header {\n  font-size: 1.5em;\n  color: black;\n  padding-bottom: 8px;\n  font-weight: bold;\n}\n  \n.KPIcard-content {\n  display: flex;\n  align-items: center;\n  padding-left: 25px;\n}\n\n.value {\n  font-size: 2.5em;\n}\n\n.unit {\n  font-size: 1.5em;\n  padding-top: 8px;\n}\n  \n@media screen and (min-width: 1270px) {\n  #MTBF {\n    padding-left : 8%;\n  }\n\n  #MTTR {\n    padding-left : 10%;\n  }\n}\n\n@media screen and (min-width: 1920px){\n  #MTBF {\n    padding-left : 5%;\n    max-width: 40%;\n  }\n  \n  #MTTR {\n    padding-left : 5%;\n    max-width: 40%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
