import { combineReducers } from 'redux';
import dashboardReducer from './dashboard';
import machineReducer from './machine';
import qualityAnalyticsReducer from './QualityAnalytics';
import tankMasterReducer from './TankMaster';
import lineReducare from './line';
import assetAnalyticsReducer from './AssetAnalytics';


const allReducers = {
	dashboard: dashboardReducer,
	qualityAnalytics:qualityAnalyticsReducer,
	machine: machineReducer,
	tankmaster:tankMasterReducer,
	line:lineReducare,
	assetAnalytics:assetAnalyticsReducer
};
const reducers = combineReducers(allReducers);

export default reducers;
