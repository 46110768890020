const initialState = {
	loadingEventList: false,
	loadingSeverityList: false,
	loadingAlertDetails: false,
	loadingCreateAlert:false,
	eventStatusList: [],
	severityList: [],
	assetAlertdetails: [],
	createAssetAlert:[]
};

const assetAnalyticsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'FETCH_EVENT_STATUS_LIST_REQUESTED':
		return {
			...state,
			loadingEventList: true,
			eventStatusList: []
		};
	case 'FETCH_EVENT_STATUS_LIST_SUCCEEDED':
		return {
			...state,
			loadingEventList: false,
			eventStatusList: payload
		};
	case 'FETCH_EVENT_STATUS_LIST_FAILED':
		return {
			...state,
			loadingEventList: false
		};

	case 'FETCH_SEVERITY_LIST_REQUESTED':
		return {
			...state,
			loadingSeverityList: true,
			severityList: []
		};
	case 'FETCH_SEVERITY_LIST_SUCCEEDED':
		return {
			...state,
			loadingSeverityList: false,
			severityList: payload
		};
	case 'FETCH_SEVERITY_LIST_FAILED':
		return {
			...state,
			loadingSeverityList: false
		};
	
	case 'FETCH_EVENT_CONFIGURATION_REQUESTED':
		return {
			...state,
			loadingAlertDetails: true,
			assetAlertdetails: []
		};
	case 'FETCH_EVENT_CONFIGURATION_SUCCEEDED':
		return {
			...state,
			loadingAlertDetails: false,
			assetAlertdetails: payload
		};
	case 'FETCH_EVENT_CONFIGURATION_FAILED':
		return {
			...state,
			loadingAlertDetails: false
		};

	case 'CREATE_ASSET_ALERT_REQUESTED':
		return {
			...state,
			loadingCreateAlert: true,
			createAssetAlert: []
		};
	case 'CREATE_ASSET_ALERT_SUCCEEDED':
		return {
			...state,
			loadingCreateAlert: false,
			createAssetAlert: payload
		};
	case 'CREATE_ASSET_ALERT_FAILED':
		return {
			...state,
			loadingCreateAlert: false
		};

	default:
		return {
			...state
		};
	}
};

export default assetAnalyticsReducer;
